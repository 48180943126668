import { createReducer, SerializedError } from "@reduxjs/toolkit"
import {
  getPupilSocialEmotionalNotes,
  createPupilSocialEmotionalNote,
  updatePupilSocialEmotionalNote,
  deletePupilSocialEmotionalNote,
} from "./social-emotional.actions"

export interface PupilSocialEmotionalNote {
  id: number
  pupilId: number
  yearclassId: number
  categoryId: number
  categoryName: string
  content: string
  position: number
  yearclassAcademicYear: number
  yearclassCalendarYear: number
  userCreatedId: number
  userCreatedName: string
  dateCreated: string
  userChangedId: number | null
  userName: string | null
  dateChanged: string | null
  userArchivedId: number | null
  userArchivedName: string | null
  dateArchived: string | null
}

interface State {
  notes: PupilSocialEmotionalNote[]
  isCreating: boolean
  isUpdating: boolean
  isDeleting: boolean
  error: SerializedError | null
}

const initialState: State = {
  notes: [],
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(getPupilSocialEmotionalNotes.pending, (state) => state)
  builder.addCase(getPupilSocialEmotionalNotes.fulfilled, (state, action) => {
    state.notes = action.payload
  })
  builder.addCase(getPupilSocialEmotionalNotes.rejected, (state, action) => {
    state.error = action.error
  })
  builder.addCase(createPupilSocialEmotionalNote.pending, (state) => {
    state.isCreating = true
  })
  builder.addCase(createPupilSocialEmotionalNote.fulfilled, (state, action) => {
    state.isCreating = false
    state.notes.push(action.payload)
  })
  builder.addCase(createPupilSocialEmotionalNote.rejected, (state, action) => {
    state.isCreating = false
    state.error = action.error
  })
  builder.addCase(updatePupilSocialEmotionalNote.pending, (state) => {
    state.isUpdating = true
  })
  builder.addCase(updatePupilSocialEmotionalNote.fulfilled, (state, action) => {
    state.isUpdating = false
    const updatedIndex = state.notes.findIndex(
      (note) => note.id === action.meta.arg.id,
    )

    if (updatedIndex !== -1) {
      state.notes[updatedIndex] = action.payload
    }
  })
  builder.addCase(updatePupilSocialEmotionalNote.rejected, (state, action) => {
    state.isUpdating = false
    state.error = action.error
  })
  builder.addCase(deletePupilSocialEmotionalNote.pending, (state) => {
    state.isDeleting = true
  })
  builder.addCase(deletePupilSocialEmotionalNote.fulfilled, (state, action) => {
    state.isDeleting = false
    state.notes = state.notes.filter((note) => note.id !== action.meta.arg)
  })
  builder.addCase(deletePupilSocialEmotionalNote.rejected, (state, action) => {
    state.isDeleting = false
    state.error = action.error
  })
})
