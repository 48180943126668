import * as React from "react"
import classnames from "classnames"

import css from "./loader.module.css"

export const LULoader = ({ isLoading, isFullScreen = true, children }) =>
  isLoading ? (
    <div
      className={classnames(css.loader, {
        [css["loader--internal"]]: !isFullScreen,
      })}>
      <div className={css.loader__spinner1} />
      <div className={css.loader__spinner2} />
    </div>
  ) : (
    children
  )
