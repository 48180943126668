import * as React from "react"
import cx from "classnames"
import { isEmpty } from "@leeruniek/functies"

import css from "./message.module.css"

export const LUMessage = ({
  className = "",
  type = "info",
  content = "",
  children = null,
  hideAfter = undefined,
  ...props
}) => {
  const [isHidden, setHidden] = React.useState(false)

  const hide = () => {
    if (!isHidden) {
      setTimeout(() => {
        setHidden(true)
      }, hideAfter)
    }
  }

  React.useEffect(() => {
    if (hideAfter) {
      hide()
    }
  }, [])

  React.useEffect(() => {
    if (hideAfter !== undefined) {
      hide()
    }
  }, [hideAfter])

  return (
    <div
      className={cx(css[`message--${type}`], {
        [className || ""]: !isEmpty(className),
        [css["message--hidden"]]: isHidden,
      })}
      data-testid={props["data-testid"]}>
      <i
        className={cx("fa", css.icon, {
          "fa-check": type === "success",
          "fa-info-circle": type === "info",
          "fa-exclamation-triangle": type === "error",
        })}
      />
      {children || content}
    </div>
  )
}
