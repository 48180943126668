import getSchoolYearClasses from "./school-year-classes"

function compareYearclasses(yearclass, otherYearclass) {
  const labelA = yearclass.yearClassName
  const labelB = otherYearclass.yearClassName

  const matchesFirstGradeA = labelA.match(/\d+?/)
  const matchesFirstGradeB = labelB.match(/\d+?/)

  const firstGradeA = matchesFirstGradeA ? matchesFirstGradeA[0] : ""
  const firstGradeB = matchesFirstGradeB ? matchesFirstGradeB[0] : ""

  if (firstGradeA !== firstGradeB) {
    return firstGradeA.localeCompare(firstGradeB)
  }

  const gradeA = yearclass.yearClassGrade
  const gradeB = otherYearclass.yearClassGrade

  if (gradeA !== gradeB) {
    return gradeA > gradeB ? 1 : -1
  }

  return labelA.localeCompare(labelB)
}

function filterCurrentYearClasses(yearclasses, year) {
  const currentClasses = getSchoolYearClasses(yearclasses, year)

  return currentClasses.sort((a, b) => compareYearclasses(a, b))
}

function buildClassGroups(yearclasses, year) {
  const filteredClasses = filterCurrentYearClasses(yearclasses, year)
  const groups = filteredClasses.reduce((groups, yearClass) => {
    if (groups[yearClass.yearClassName]) {
      groups[yearClass.yearClassName].classes.push(yearClass)
    } else {
      groups[yearClass.yearClassName] = {
        groupId: yearClass.yearClassId,
        groupName: yearClass.yearClassName,
        groupLongYear: `${yearClass.calendarYear}/${
          yearClass.calendarYear + 1
        }`,
        classes: [yearClass],
      }
    }

    return groups
  }, {})

  return Object.values(groups).sort(
    (groupA, groupB) =>
      groupA.classes[0].yearClassGrade - groupB.classes[0].yearClassGrade ||
      groupA.classes.length - groupB.classes.length ||
      groupA.groupName
        .replace(/ /g, "")
        .localeCompare(groupB.groupName.replace(/ /g, "")),
  )
}

function buildYearGroups(yearclasses, year) {
  const filteredClasses = filterCurrentYearClasses(yearclasses, year)
  const groups = filteredClasses.reduce((groups, yearClass) => {
    if (groups[yearClass.yearClassGrade]) {
      groups[yearClass.yearClassGrade].classes.push(yearClass)
    } else {
      groups[yearClass.yearClassGrade] = {
        groupName: yearClass.yearClassGrade,
        groupLongYear: `${yearClass.calendarYear}/${
          yearClass.calendarYear + 1
        }`,
        classes: [yearClass],
      }
    }

    return groups
  }, {})

  return Object.values(groups)
    .filter((group) => parseInt(group.classes[0].yearClassGrade, 10) >= 3)
    .sort(
      (groupA, groupB) =>
        groupA.classes[0].yearClassGrade - groupB.classes[0].yearClassGrade,
    )
}

export default function buildGroups(
  yearclasses,
  groupingType,
  sessionSchoolYear,
) {
  switch (groupingType) {
    case "class":
      return buildClassGroups(yearclasses, sessionSchoolYear)
    case "year":
      return buildYearGroups(yearclasses, sessionSchoolYear)
    default:
      return []
  }
}
