import { isNil } from "ramda"
import RequestError from "lib/core/errors/request.error"

import { init } from "@sentry/browser"

export const load = () => {
  // Log errors using sentry.io
  try {
    init({
      dsn: WEBP_SENTRY_DSN,
      environment: WEBP_SENTRY_ENV,
      release: WEBP_COMMIT_HASH,
      ignoreErrors: ["RequestError"],
      beforeSend: (event, hint) => {
        if (hint) {
          const error = hint.originalException

          // Don't capture RequestErrors if they originate from a HTTP 401
          // response
          const isAuthRequestError = (e) =>
            e instanceof RequestError && error._isAuthError

          if (!isNil(error) && isAuthRequestError(error)) {
            return null
          }
        }

        return event
      },
    })
  } catch (e) {
    console.log("failed to initialize sentry: " + e)
  }
}
