import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["activation-guide"]: {
    nl: `
      <ol>
        <li>Installeer een authenticatie-app op je telefoon</li>
        <li>Scan de QR-code in je authenticatie-app</li>
        <li>Vul hieronder de 6-cijferige verificatiecode in <br/>gegeven door de authenticatie-app</li>
      </ol>
      `,
    en: `
      <ol>
        <li>Install an authenticator app</li>
        <li>Scan the QR-code with your Authenticator app</li>
        <li>Enter the six digits generated by your authenticator</li>
      </ol>
      `,
  },
  ["deactivation-guide"]: {
    nl: `
        Vul de 6 cijfers in die uw authenticator heeft gegenereerd voor uw Leeruniek-account
      `,
    en: `
        Enter the six digits generated by your authenticator for your Leeruniek account
      `,
  },
  ["activation-title"]: {
    nl: "Activeer je tweestapsverificatie",
    en: "Activate your two-step authentication",
  },
  ["deactivation-title"]: {
    nl: "Deactiveer je tweestapsverificatie",
    en: "Deactivate your two-step authentication",
  },
  ["code-placeholder"]: {
    nl: "------",
    en: "------",
  },
  ["code__empty"]: {
    nl: "Geen code ingevoerd",
    en: "No code entered",
  },
  ["code__invalid"]: {
    nl: "De ingevoerde code is niet geldig",
    en: "The entered code is not valid",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
