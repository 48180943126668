import { createReducer, SerializedError } from "@reduxjs/toolkit"
import {
  getAdminTemplates,
  createAdminTemplate,
  updateAdminTemplate,
  deleteAdminTemplate,
  getRecentPlans,
  getNoteCategoriesPreview,
  clearNoteCategoriesPreview,
  getGroupPlanNotesPreview,
  clearGroupPlanNotesPreview,
} from "entities/admin__templates.page/overview.section/overview.actions"
import {
  GroupPlan,
  GroupPlanNote,
  GroupPlanTemplate,
} from "entities/group-plans/group-plans.interfaces"
import { NoteCategory } from "entities/note-categories/note-categories.interfaces"

export const initialState: State = {
  templates: [],
  recentPlans: [],
  noteCategories: [],
  groupPlanNotes: [],
  templatesUpdatingIds: [],
  isTemplatesLoading: false,
  isCategoriesLoading: false,
  isNotesLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
}

interface State {
  templates: GroupPlanTemplate[]
  recentPlans: GroupPlan[]
  noteCategories: NoteCategory[]
  groupPlanNotes: GroupPlanNote[]
  templatesUpdatingIds: number[]
  isTemplatesLoading: boolean
  isCategoriesLoading: boolean
  isNotesLoading: boolean
  isCreating: boolean
  isUpdating: boolean
  isDeleting: boolean
  error: SerializedError | null
}

export default createReducer(initialState, (builder) => {
  builder.addCase(getAdminTemplates.pending, (state) => {
    state.isTemplatesLoading = true
  })
  builder.addCase(getAdminTemplates.fulfilled, (state, action) => {
    state.isTemplatesLoading = false
    state.templates = action.payload
  })
  builder.addCase(getAdminTemplates.rejected, (state, action) => {
    state.isTemplatesLoading = false
    state.error = action.error
  })
  builder.addCase(createAdminTemplate.pending, (state) => {
    state.isCreating = true
  })
  builder.addCase(createAdminTemplate.fulfilled, (state, action) => {
    state.isCreating = false
    state.templates.push(action.payload)
  })
  builder.addCase(createAdminTemplate.rejected, (state, action) => {
    state.isCreating = false
    state.error = action.error
  })
  builder.addCase(updateAdminTemplate.pending, (state) => {
    state.isUpdating = true
  })
  builder.addCase(updateAdminTemplate.fulfilled, (state, action) => {
    state.isUpdating = false
    const updatedIndex = state.templates.findIndex(
      (template) => template.id === action.payload.id,
    )
    state.templates[updatedIndex] = action.payload
  })
  builder.addCase(updateAdminTemplate.rejected, (state, action) => {
    state.isUpdating = false
    state.error = action.error
  })
  builder.addCase(deleteAdminTemplate.pending, (state) => {
    state.isDeleting = true
  })
  builder.addCase(deleteAdminTemplate.fulfilled, (state, action) => {
    state.isDeleting = false
    state.templates = state.templates.filter(
      (template) => template.id !== action.payload.id,
    )
  })
  builder.addCase(deleteAdminTemplate.rejected, (state, action) => {
    state.isDeleting = false
    state.error = action.error
  })
  builder.addCase(getRecentPlans.pending, (state) => state)
  builder.addCase(getRecentPlans.fulfilled, (state, action) => {
    state.recentPlans = action.payload
  })
  builder.addCase(getRecentPlans.rejected, (state, action) => {
    state.error = action.error
  })
  builder.addCase(getNoteCategoriesPreview.pending, (state) => {
    state.isCategoriesLoading = true
  })
  builder.addCase(getNoteCategoriesPreview.fulfilled, (state, action) => {
    state.isCategoriesLoading = false
    state.noteCategories = action.payload
  })
  builder.addCase(getNoteCategoriesPreview.rejected, (state, action) => {
    state.isCategoriesLoading = false
    state.error = action.error
  })
  builder.addCase(clearNoteCategoriesPreview, (state) => {
    state.noteCategories = []
  })
  builder.addCase(getGroupPlanNotesPreview.pending, (state) => {
    state.isNotesLoading = true
  })
  builder.addCase(getGroupPlanNotesPreview.fulfilled, (state, action) => {
    state.isNotesLoading = false
    state.groupPlanNotes = action.payload
  })
  builder.addCase(getGroupPlanNotesPreview.rejected, (state, action) => {
    state.isNotesLoading = false
    state.error = action.error
  })
  builder.addCase(clearGroupPlanNotesPreview, (state) => {
    state.groupPlanNotes = []
  })
})
