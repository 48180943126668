import { reduxStore as store } from "store"
import * as DoorbellAPI from "entities/doorbell/doorbell.api"

export const DOORBEL_SUBMIT_LOADING = "DOORBEL_SUBMIT_LOADING"
export const DOORBEL_SUBMIT_ERROR = "DOORBEL_SUBMIT_ERROR"
export const DOORBEL_SUBMIT_SUCCESS = "DOORBEL_SUBMIT_SUCCESS"
export const DOORBEL_OPEN_ERROR = "DOORBEL_OPEN_ERROR"
export const DOORBEL_OPEN_SUCCESS = "DOORBEL_OPEN_SUCCESS"

/**
 * Trigger when the feedback form is showed
 */
export const openTicket = async () => {
  try {
    const response = await DoorbellAPI.open()

    store.dispatch({
      type: DOORBEL_OPEN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    store.dispatch({
      type: DOORBEL_OPEN_ERROR,
      payload: {
        error,
      },
    })
  }
}

/**
 * Trigger when the feedback form is submited
 *
 * @param  {Object}  arg1             Request body
 * @param  {string}  arg1.email       User email
 * @param  {string}  arg1.message     User message
 * @param  {string}  arg1.name        User name
 * @param  {string}  arg1.screenshot  Screen image data
 * @param  {Object}  arg1.properties  Other meta info
 *
 * @return {undefined}
 */
export const submitTicket = async ({
  email,
  message,
  name,
  screenshot,
  properties,
  attachments,
}) => {
  store.dispatch({
    type: DOORBEL_SUBMIT_LOADING,
    payload: {
      isLoading: true,
    },
  })

  try {
    const response = await DoorbellAPI.submit({
      email,
      message,
      name,
      screenshot,
      properties,
      attachments,
    })

    store.dispatch({
      type: DOORBEL_SUBMIT_SUCCESS,
      payload: {
        body: response,
        ok: true,
      },
    })
  } catch (error) {
    store.dispatch({
      type: DOORBEL_SUBMIT_ERROR,
      payload: {
        body: error,
        ok: false,
      },
    })
  }
}
