import { createAction } from "@reduxjs/toolkit"
import { map, includes, reduce } from "ramda"
import { merge, filterBy, remove, hasWith } from "@leeruniek/functies"

import ReducerError from "lib/core/errors/reducer.error"
import * as StorageAPI from "lib/core/storage.api"
import { LOGIN_REDIRECT_STORAGE_KEY } from "lib/leeruniek/constants"

/**
 * Wrap the reducer function with a try/catch. Use a custom error to give more
 * context
 *
 * @param  {Object<string, Function>}  reducers    Reducers
 * @param  {Object}                    arg2        Props
 * @param  {string}                    arg2.slice  Store slice name
 *
 * @return {Object<string, Function>}  Mirror object with the reducer function
 *                                     wrapped
 */
export const catchErrors = (reducers, { slice }) => {
  const obj = Object.entries(reducers).reduce(
    (acc, [reducerName, reducerFunction]) => ({
      ...acc,
      [reducerName]: (...args) => {
        try {
          return reducerFunction(...args)
        } catch (error) {
          throw new ReducerError(`${slice}:${reducerName}`, error)
        }
      },
    }),
    {},
  )

  return obj
}

/**
 * State structure for lists
 */
export const listDefaultState = {
  items: [],
  errors: [],

  creatingItem: {},
  updatingIds: [],
  deletingIds: [],
  lastLoadAt: null,

  isLoading: false,
  isReloading: false,
  isCreating: false,
}

/**
 * Enable UI flag for creating new item
 *
 * @param {Object}  state  The state
 *
 * @return {Object}
 */
export const listCreateStart = (state, { creatingItem = {} } = {}) => ({
  ...state,
  creatingItem,
  isCreating: true,
})

/**
 * Add new item, acts as upsert
 *
 * @param  {Object}  state             The state
 * @param  {Object}  arg2              The argument 2
 * @param  {Object}  arg2.createdItem  The created item
 *
 * @return {Object}
 */
export const listCreateEnd = (state, { createdItem }) => {
  const exists = hasWith({ id: createdItem.id })(state.items)

  return {
    ...state,
    items: exists
      ? state.items.map((element) =>
          element.id === createdItem.id ? createdItem : element,
        )
      : [...state.items, createdItem],
    isCreating: false,
    creatingItem: {},
  }
}

/**
 * Add new items, merges lists and replaces existing by ID
 *
 * @param  {Object}  state             The state
 * @param  {Object}  arg2              The argument 2
 * @param  {Object}  arg2.createdItem  The created item
 *
 * @return {Object}
 */
export const listBulkCreateEnd = (state, { created }) => ({
  ...state,
  items: reduce(
    (acc, newElement) =>
      hasWith({ id: newElement.id })(acc)
        ? // array__mergeWith with custom function to decide if append or merge
          map((existingElement) =>
            newElement.id === existingElement.id
              ? { ...existingElement, ...newElement }
              : existingElement,
          )(acc)
        : [...acc, newElement],
    state.items,
  )(created),
  isCreating: false,
  creatingItem: {},
})

/**
 * Enable UI flag for list loading
 *
 * @param {Object}  state  The state
 *
 * @return {Object}
 */
export const listLoadStart = (state) => ({
  ...state,
  isLoading: true,
  isReloading: !state.lastLoadAt,
})

/**
 * Add newly received items, keep list without duplicates
 *
 * @param {Object}  state  The state
 *
 * @return {Object}
 */
export const listLoadEnd = (state, { items }) => ({
  ...state,
  items,
  isLoading: false,
  isReloading: false,
  lastLoadAt: new Date().toISOString(),
})

/**
 * Enable UI flag for updating item
 *
 * @param  {Object}  state  The state
 *
 * @return {Object}
 */
export const listUpdateStart = (state, { id }) => {
  const isAlreadyUpdating = includes(id)(state.updatingIds)

  return {
    ...state,
    updatingIds: isAlreadyUpdating
      ? state.updatingIds
      : [...state.updatingIds, id],
  }
}

/**
 * Update new item in the list
 *
 * @param  {Object}  state             The state
 * @param  {Object}  arg2              Payload
 * @param  {Object}  arg2.updatedItem  API response
 *
 * @return {Object}
 */
export const listUpdateEnd = (state, { updatedItem }) => ({
  ...state,
  updatingIds: remove(updatedItem.id)(state.updatingIds),
  items: state.items.map((element) =>
    element.id === updatedItem.id ? merge(element, updatedItem) : element,
  ),
})

/**
 * Enable UI flag for removing item
 *
 * @param  {Object}  state    The state
 * @param  {Object}  arg2     Payload
 * @param  {Object}  arg2.id  Item id
 *
 * @return {Object}
 */
export const listDeleteStart = (state, { id }) => {
  const isDeleting = includes(id)(state.deletingIds)

  return {
    ...state,
    deletingIds: isDeleting ? state.deletingIds : [...state.deletingIds, id],
  }
}

/**
 * Remove item from items array
 *
 * @param  {Object}  state    The state
 * @param  {Object}  arg2     Payload
 * @param  {Object}  arg2.id  Item id
 *
 * @return {Object}
 */
export const listDeleteEnd = (state, { id }) => ({
  ...state,
  deletingIds: remove(id)(state.deletingIds),
  items: filterBy({
    "!id": id,
  })(state.items),
})

export const handleServerError = (error) => {
  if (error._isAuthError) {
    // remove login_redirect so user does not enter endless login-logged out cycle
    StorageAPI.remove(LOGIN_REDIRECT_STORAGE_KEY)
    return createAction("AUTHORISATION_ERROR", (error) => error)(error)
  } else if (error._isAccessError) {
    // remove login_redirect so user does not enter endless login-logged out cycle
    StorageAPI.remove(LOGIN_REDIRECT_STORAGE_KEY)
    return createAction("ACCESS_ERROR", (error) => error)(error)
  } else if (error._isNotFoundError) {
    return createAction("NOT_FOUND_ERROR", (error) => error)(error)
  } else {
    return createAction("UNHANDLED_ERROR", (error) => error)(error)
  }
}

// Given a name and an async function, create an asynchronous action
// using redux-thunk. Two actions will be dispatched:
// - on start (before the async function is executed)
// - on success/failure (after the async function completes)
export const createAsyncAction =
  (actionName, asyncFn, shouldDispatchServerError = false) =>
  (...asyncFnParams) =>
  (dispatch) => {
    const { request, success, failure } = {
      request: `${actionName}_REQUEST`,
      success: `${actionName}_SUCCESS`,
      failure: `${actionName}_FAILURE`,
    }

    const requestAction = createAction(request)
    const successAction = createAction(success)
    const failureAction = createAction(failure)

    dispatch(requestAction(asyncFnParams))

    return asyncFn(...asyncFnParams)
      .then((data) => dispatch(successAction(data)))
      .catch((err) => {
        console.debug(err)

        if (shouldDispatchServerError) {
          dispatch(handleServerError(err))
        }

        dispatch(failureAction(err))
      })
  }
