import { createReducer } from "@reduxjs/toolkit"
import { isNil, isEmpty } from "ramda"
import { API_ERRORS } from "lib/leeruniek/constants"

const initialState = {
  user: {},
  isWaitingForLogin: false,
  isUserLoading: false,
  isUserAuthorized: false,
  schoolId: null,
  selectedSubject: null,
  hasErrors: false,
  hasNoConnectedPayingSchoolError: false,
  isSchoolAmbitionVisible: false,
  isImpersonated: false,
  isMultiFactorAuthAppRequired: false,
  isMultiFactorAuthAppCodeInvalid: false,
  account: {
    userInfo: {
      isUpdating: false,
      error: null,
      isUpdateSucceed: false,
    },
    userPassword: {
      isUpdating: false,
      error: null,
      isUpdateSucceed: false,
    },
    shouldClientAutoUpdate: false,
  },
  //TODO: after moving to the chart library restructure this slice

  settings: {
    showNationalAverage: false,
    showBarAverage: true,
    aggregationType: "year", // possible: 'year', 'group'
    preferredTestsView: {},
  },
  errors: {},
  dirtyForms: [],
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("LOAD_USER_PROFILE_REQUEST", (state) => ({
      ...state,
      isUserLoading: true,
      isUserAuthorized: false,
    }))
    .addCase("LOAD_USER_PROFILE_SUCCESS", (state, action) => {
      const { schools, user, schoolId, isImpersonated } = action.payload

      return {
        ...state,
        user,
        schools,
        schoolId,
        isImpersonated,
        //serverSchoolYear is current school year, received from api. This value never changed in frontend app
        serverSchoolYear: user.currentSchoolYear,
        //sessionSchoolYear used for switching school years to see historical data
        sessionSchoolYear: user.currentSchoolYear,
        isUserLoading: false,
        isUserAuthorized: true,
      }
    })
    .addCase("LOAD_USER_PROFILE_FAILURE", (state) => ({
      ...state,
      isUserLoading: false,
      isUserAuthorized: false,
      hasErrors: true,
    }))
    .addCase("USER_LOGIN_REQUEST", (state) => ({
      ...state,
      isWaitingForLogin: true,
    }))
    .addCase("USER_LOGIN_SUCCESS", (state) => {
      return {
        ...state,
        isWaitingForLogin: false,
        isUserAuthorized: true,
        hasNoConnectedPayingSchoolError: false,
        isMultiFactorAuthAppRequired: false,
        isMultiFactorAuthAppCodeInvalid: false,
      }
    })
    .addCase("USER_LOGIN_FAILURE", (state, a) => {
      const nonFieldErrors = a?.payload?.body?.nonFieldErrors
      const isMultiFactorAuthAppRequired =
        !isNil(nonFieldErrors) &&
        !isEmpty(nonFieldErrors) &&
        nonFieldErrors.includes(API_ERRORS.TOTP_CHALLENGE_REQUIRED)
      const isMultiFactorAuthAppCodeInvalid =
        !isNil(nonFieldErrors) &&
        !isEmpty(nonFieldErrors) &&
        nonFieldErrors.includes(API_ERRORS.TOTP_CHALLENGE_INVALID)
      return {
        ...state,
        isWaitingForLogin: false,
        hasErrors:
          !isMultiFactorAuthAppRequired && !isMultiFactorAuthAppCodeInvalid,
        hasNoConnectedPayingSchoolError:
          !isNil(nonFieldErrors) &&
          !isEmpty(nonFieldErrors) &&
          nonFieldErrors.includes(API_ERRORS.NO_CONNECTED_PAYING_SCHOOL_ERROR),
        isMultiFactorAuthAppRequired,
        isMultiFactorAuthAppCodeInvalid,
      }
    })
    .addCase("SESSION_LOGOUT", () => ({
      ...initialState,
    }))
    .addCase("CHANGE_SCHOOL", (state, action) => ({
      ...state,
      schoolId: action.payload.id,
    }))
    .addCase("CHANGE_SELECTED_SUBJECT", (state, action) => ({
      ...state,
      selectedSubject: action.payload,
    }))
    .addCase("CHANGE_SCHOOL_YEAR", (state, action) => ({
      ...state,
      sessionSchoolYear: action.payload,
    }))
    .addCase("SAVE_ACCOUNT_DETAILS_REQUEST", (state) => ({
      ...state,
      account: {
        ...state.account,
        userInfo: {
          isUpdating: true,
          error: null,
          isUpdateSucceed: false,
        },
      },
    }))
    .addCase("SAVE_ACCOUNT_DETAILS_SUCCESS", (state, action) => {
      const { firstName, lastName, email } = action.payload
      const fullName = `${firstName} ${lastName}`

      return {
        ...state,
        user: {
          ...state.user,
          fullName,
          email,
        },
        account: {
          ...state.account,
          userInfo: {
            isUpdating: false,
            error: null,
            isUpdateSucceed: true,
          },
        },
      }
    })
    .addCase("SAVE_ACCOUNT_DETAILS_FAILURE", (state, action) => ({
      ...state,
      account: {
        ...state.account,
        userInfo: {
          isUpdating: false,
          error: action.payload.body,
          isUpdateSucceed: false,
        },
      },
    }))
    .addCase("CHANGE_USER_PASSWORD_REQUEST", (state) => ({
      ...state,
      account: {
        ...state.account,
        userPassword: {
          isUpdating: true,
          error: null,
          isUpdateSucceed: false,
        },
      },
    }))
    .addCase("CHANGE_USER_PASSWORD_SUCCESS", (state) => ({
      ...state,
      account: {
        ...state.account,
        userPassword: {
          isUpdating: false,
          error: null,
          isUpdateSucceed: true,
        },
      },
    }))
    .addCase("CHANGE_USER_PASSWORD_FAILURE", (state, action) => ({
      ...state,
      account: {
        ...state.account,
        userPassword: {
          isUpdating: false,
          error: action.payload.body,
          isUpdateSucceed: false,
        },
      },
    }))
    .addCase("PIN_PUPIL_SUCCESS", (state, action) => ({
      ...state,
      user: {
        ...state.user,
        pinnedPupils: [...state.user.pinnedPupils, action.payload],
      },
    }))
    .addCase("UNPIN_PUPIL_SUCCESS", (state, action) => ({
      ...state,
      user: {
        ...state.user,
        pinnedPupils: [
          ...state.user.pinnedPupils.filter(
            (pupilId) => pupilId !== action.payload,
          ),
        ],
      },
    }))
    .addCase("SET_SETTING", (state, action) => {
      return {
        ...state,
        settings: action.payload,
      }
    })
    .addCase("SET_SHOW_SCHOOL_AMBITION", (state, action) => {
      return {
        ...state,
        isSchoolAmbitionVisible: action.payload,
      }
    })
    .addCase("UNHANDLED_ERROR", (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        unhandledError: action.payload,
      },
    }))
    .addCase("ACCESS_ERROR", (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        accessError: action.payload,
      },
    }))
    .addCase("AUTHORISATION_ERROR", (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        authError: action.payload,
      },
    }))
    .addCase("NOT_FOUND_ERROR", (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        notFoundError: action.payload,
      },
    }))
    .addCase("CLEAN_ERRORS", (state) => ({
      ...state,
      errors: {},
    }))
    .addCase("TRACK_CLIENT_UPDATE", (state) => ({
      ...state,
      shouldClientAutoUpdate: true,
    }))
    .addCase("GET_USER_SETTINGS_REQUEST", (state) => ({
      ...state,
      settings: { ...state.settings, isLoading: true },
    }))
    .addCase("GET_USER_SETTINGS_SUCCESS", (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        preferredTestsView: action.payload,
        isLoading: false,
      },
    }))
    .addCase("GET_USER_SETTINGS_FAILURE", (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        errors: [action.payload],
        isLoading: false,
      },
    }))
    .addCase("CREATE_USER_SETTINGS_REQUEST", (state) => ({
      ...state,
      settings: { ...state.settings, isLoading: true },
    }))
    .addCase("CREATE_USER_SETTINGS_SUCCESS", (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        preferredTestsView: action.payload,
        isLoading: false,
      },
    }))
    .addCase("CREATE_USER_SETTINGS_FAILURE", (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        errors: [action.payload],
        isLoading: false,
      },
    }))
    .addCase("UPDATE_USER_SETTINGS_REQUEST", (state) => ({
      ...state,
      settings: { ...state.settings, isLoading: true },
    }))
    .addCase("UPDATE_USER_SETTINGS_SUCCESS", (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        preferredTestsView: {
          ...state.settings.preferredTestsView,
          ...action.payload,
        },
        isLoading: false,
      },
    }))
    .addCase("UPDATE_USER_SETTINGS_FAILURE", (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        errors: [action.payload],
        isLoading: false,
      },
    }))
})
