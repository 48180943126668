import { GET, POST } from "lib/core/api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { serializeError } from "lib/core/redux-tools.utils"
import {
  PupilInstructionGroup,
  PupilInstructionGroupHistoryItem,
} from "entities/pupils/pages/profile/instruction-groups/instruction-groups.reducer"
import { map } from "ramda"

export const getPupilInstructionGroups = createAsyncThunk<
  PupilInstructionGroup[],
  number
>(
  "pupilInstructionGroups/getPupilInstructionGroups",
  async (pupilId: number) => {
    const result = await GET("/pupil_approaches/", {
      query: {
        pupil_id: pupilId,
      },
    })

    return result as PupilInstructionGroup[]
  },
  {
    serializeError,
  },
)

export const createPupilInstructionGroup = createAsyncThunk<
  PupilInstructionGroup,
  { pupilId: number; instructionGroup: string; subjectId: number }
>(
  "pupilInstructionGroups/createPupilInstructionGroup",
  async (data) => {
    const result = await POST("/pupil_approaches/", {
      body: {
        ref: "pupil",
        approach: data.instructionGroup,
        subjectId: data.subjectId,
        pupilId: data.pupilId,
      },
    })

    return result as PupilInstructionGroup
  },
  {
    serializeError,
  },
)

export const getPupilInstructionGroupsHistory = createAsyncThunk<any, number>(
  "pupilInstructionGroups/getPupilInstructionGroupsHistory",
  async (pupilId) => {
    const result = await GET(
      "/pupil_approach_logs/get_approach_logs_for_pupil",
      {
        query: {
          pupil_id: pupilId,
        },
      },
    ).then(
      map((elm: PupilInstructionGroupHistoryItem) => ({
        ...elm,
        schoolYear: elm.pupilApproach.schoolYear,
        subjectId: elm.pupilApproach.subjectId,
        yearclassId: elm.pupilApproach.yearclassId,
      })),
    )

    return result as PupilInstructionGroupHistoryItem[]
  },
  {
    serializeError,
  },
)
