import React from "react"
import moment from "moment"
import { createRoot } from "react-dom/client"
import { trackClientUpdate } from "entities/sessions/sessions.actions"
import { reduxStore } from "store"
import { load as loadSentry } from "lib/core/sentry"

import App from "./root"
import "static/scss/root.scss"
import { initializeHighcharts } from "lib/core/highcharts"

// Will move in language switch action at some point
moment.locale(WEBP_LANGUAGE)

initializeHighcharts()

if (WEBP_ENV !== "development") {
  mixpanel.init(WEBP_MIXPANEL_TOKEN)
  loadSentry()
}

const root = createRoot(document.getElementById(WEBP_REACT_ROOT_ID))
root.render(<App />)

if (WEBP_ENV !== "production") {
  // debug console only app specific
  window.localStorage.setItem("debug", "Leeruniek:*")
}

// Setup the service worker
// We use it to reload the frontend code on the client-side such that when we publish new code clients start using this code instead of the one cached in the browser (cache-busting).
// We do so by updating the serviceWorker periodically, and detecting when there is new code to be fetched. Once this occurs, we dispatch trackClientUpdate to the redux store.
// On route change, we check this flag, and if it is true, we do `window.location.reload()` for the user to apply the newest code.
if ("serviceWorker" in navigator && WEBP_ENV !== "development") {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        registration.onupdatefound = () => {
          reduxStore.dispatch(trackClientUpdate())
        }

        const REFRESH_INTERVAL_TO_CHECK_FOR_NEW_RELEASE = 1000 * 60 * 1
        setInterval(() => {
          registration.update()
        }, REFRESH_INTERVAL_TO_CHECK_FOR_NEW_RELEASE)
      })
  })
}
