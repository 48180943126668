import { reduxStore as store } from "store"
import * as NoteCategoriesAPI from "entities/note-categories/note-categories.api"

export const NOTE_CATEGORIES_AUTOCOMPLETE_CREATE_START = "NCACC_S"
export const NOTE_CATEGORIES_AUTOCOMPLETE_CREATE_END = "NCACC_E"
export const NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_START = "NCACL_S"
export const NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_END = "NCACL_E"
export const NOTE_CATEGORIES_AUTOCOMPLETE_UPDATE_START = "NCACU_S"
export const NOTE_CATEGORIES_AUTOCOMPLETE_UPDATE_END = "NCACU_E"
export const NOTE_CATEGORIES_AUTOCOMPLETE_DELETE_START = "NCACD_S"
export const NOTE_CATEGORIES_AUTOCOMPLETE_DELETE_END = "NCACD_E"

/**
 * { function_description }
 *
 * @param  {string}   name     The name
 * @param  {string}   layer    The layer
 * @param  {integer}  schoolId The school ID
 *
 * @return {Object}
 */
export const create = async ({ name, layer, schoolId = null } = {}) => {
  const category = await NoteCategoriesAPI.create({
    name,
    layer,
    schoolId,
  })

  return category
}

/**
 * { function_description }
 *
 * @param  {integer}    pupilId  The pupil identifier
 * @param  {integer}    schoolId  The school identifier
 *
 * @return {undefined}
 */
export const findAllAutocomplete = async (
  pupilId,
  schoolId,
  { setIsLoading = true } = {},
) => {
  if (setIsLoading) {
    store.dispatch({
      type: NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_START,
    })
  }

  const categories = await NoteCategoriesAPI.findAllAutocomplete(
    pupilId,
    schoolId,
  )

  store.dispatch({
    type: NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_END,
    payload: {
      items: categories,
    },
  })
}
