import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["unexpected__error__message__oeps"]: {
    nl: "Oeps...",
    en: "Oops...",
  },
  ["unexpected__error__message__part__1"]: {
    nl: "We hebben last van een technisch mankement.",
    en: "We are currently experiencing a technical problem.",
  },
  ["unexpected__error__message__part__2"]: {
    nl: "Wij zijn van het probleem op de hoogte gesteld en het probleem wordt zo snel mogelijk verholpen.",
    en: "Our team has been notified about the problem and is working on the solution.",
  },

  ["unexpected__error__message__part__3"]: {
    nl: "Blijft het probleem zich voordoen, neem dan contact op met support via de oranje feedback knop onderaan de pagina. Zij helpen je graag verder.",
    en: "If the problem persists, please contact support by clicking on the orange feedback button.",
  },
  ["404_error_message_part_1"]: {
    nl: "Helaas kunnen we de pagina die je probeert te zoeken niet vinden.",
    en: "Unfortunately, we cannot find the page that you are trying to visit.",
  },
  ["404_error_message_part_2"]: {
    nl: "Wellicht is de pagina verwijderd of verplaatst.",
    en: "The page may have been deleted or moved.",
  },
  ["access_error_message_part_1"]: {
    nl: "Je hebt helaas geen toegang tot deze pagina.",
    en: "Unfortunately you do not have access to this page.",
  },
  ["access_error_message_part_2"]: {
    nl: "Vraag aan de beheerder van je school om jou de benodigde toegangsrechten te verlenen.",
    en: "Ask the administrator of your school to grant you the necessary access rights.",
  },
  ["error__go__homepage"]: {
    nl: "Ga naar de startpagina",
    en: "Go to the homepage",
  },
  ["unexpected__error__contact__support"]: {
    nl: "Contact het support team",
    en: "Contact Support",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
