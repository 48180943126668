import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { GET, POST, PATCH, DELETE } from "lib/core/api"
import { serializeError } from "lib/core/redux-tools.utils"
import { GroupPlanNote } from "entities/group-plans/group-plans.interfaces"
import {
  NoteCategory,
  NoteCategoryAutocomplete,
} from "entities/note-categories/note-categories.interfaces"
import { map } from "ramda"

export const getNoteCategories = createAsyncThunk<NoteCategory[], number>(
  "selectedGroupPlan/getNoteCategories",
  async (planId) => {
    const result = await GET("/note_categories", {
      query: {
        group_plan_id: planId,
        "include[]":
          "id,name,parent_note_category_id,position,date_created,date_changed",
      },
    }).then(
      map((item: NoteCategory) => ({
        ...item,
        isCollapsed: false,
      })),
    )

    return result as NoteCategory[]
  },
  { serializeError },
)

export const createNoteCategory = createAsyncThunk<
  NoteCategory,
  {
    planId: number
    parentNoteCategoryId: number
    name: string
    position: number
  }
>(
  "selectedGroupPlan/createNoteCategory",
  async (args) => {
    const result = await POST("/note_categories", {
      layer: "GROUP",
      group_plan_id: args.planId,
      parent_note_category_id: args.parentNoteCategoryId,
      name: args.name,
      position: args.position,
    }).then((category: NoteCategory) => ({
      ...category,
      isCollapsed: false,
    }))

    return result as NoteCategory
  },
  { serializeError },
)

export const updateNoteCategory = createAsyncThunk<
  NoteCategory,
  { id: number; data: Partial<NoteCategory> }
>(
  "selectedGroupPlan/updateNoteCategory",
  async (args) => {
    const { isCollapsed, ...data } = args.data
    const result = await PATCH(`/note_categories/${args.id}`, {
      body: data,
    }).then((category: NoteCategory) => ({
      ...category,
      isCollapsed: isCollapsed,
    }))

    return result as NoteCategory
  },
  { serializeError },
)

export const updateNoteCategoryInState = createAction(
  "selectedGroupPlan/updateNoteCategoryInState",
  (args: { id: number; data: Partial<NoteCategory> }) => ({ payload: args }),
)

export const deleteNoteCategory = createAsyncThunk<{ id: number }, number>(
  "selectedGroupPlan/deleteNoteCategory",
  async (id) => {
    const result = await DELETE(`/note_categories/${id}`)

    return result
  },
  { serializeError },
)

export const getNotes = createAsyncThunk<GroupPlanNote[], number>(
  "selectedGroupPlan/getNotes",
  async (planId) => {
    const result = await GET("/group_plan_note", {
      query: {
        category__group_plan_id: planId,
        category__is_archived: false,
        date_archived__isnull: true,
        "exclude[]": "pupil_id",
      },
    })

    return result as GroupPlanNote[]
  },
  { serializeError },
)

export const createNote = createAsyncThunk<
  GroupPlanNote,
  { categoryId: number; content: string; position: number }
>(
  "selectedGroupPlan/createNote",
  async (args) => {
    const result = await POST("/group_plan_note", {
      body: {
        category_id: args.categoryId,
        content: args.content,
        position: args.position,
      },
    })

    return result as GroupPlanNote
  },
  { serializeError },
)

export const updateNote = createAsyncThunk<
  GroupPlanNote,
  { id: number; data: Partial<GroupPlanNote> }
>(
  "selectedGroupPlan/updateNote",
  async (args) => {
    const result = await PATCH(`/group_plan_note/${args.id}`, {
      body: args.data,
    })

    return result as GroupPlanNote
  },
  { serializeError },
)

export const updateNoteInState = createAction(
  "selectedGroupPlan/updateNoteInState",
  (args: { id: number; data: Partial<GroupPlanNote> }) => ({ payload: args }),
)

export const deleteNote = createAsyncThunk<{ id: number }, number>(
  "selectedGroupPlan/deleteNote",
  async (id) => {
    const result = await DELETE(`/group_plan_note/${id}`).then(() => ({ id }))

    return result
  },
  { serializeError },
)

export const getCategoriesAutocomplete = createAsyncThunk<
  NoteCategoryAutocomplete[],
  number
>(
  "selectedGroupPlan/getCategoriesAutocomplete",
  async (planId) => {
    const result = await GET("/note_categories", {
      query: {
        "~group_plan_id": planId,
        user: true,
        parent_note_category_id__isnull: true,
        distinct_by: "name",
        "include[]": "id,name",
        layer__in: "GROUP,INSTRUCTION",
      },
    })

    return result as NoteCategoryAutocomplete[]
  },
  { serializeError },
)

export const getSubcategoriesAutocomplete = createAsyncThunk<
  NoteCategoryAutocomplete[],
  number
>(
  "selectedGroupPlan/getSubcategoriesAutocomplete",
  async (planId) => {
    const result = await GET("/note_categories", {
      query: {
        "~group_plan_id": planId,
        user: true,
        parent_note_category_id__isnull: false,
        distinct_by: "name",
        "include[]": "id,name",
        layer__in: "GROUP,INSTRUCTION",
      },
    })

    return result as NoteCategoryAutocomplete[]
  },
  { serializeError },
)
