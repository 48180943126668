import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Palette,
  PaletteColor,
  Theme,
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import MLUProgressBar from "components/ui/mluprogressbar/mluprogressbar"

export enum DialogSize {
  AUTO = "auto",
  SMALL = "small",
  NORMAL = "normal",
  LARGE = "large",
  FULLSCREEN = "fullscreen",
}

export enum ActionsAlignment {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
}

const dialogSizes: {
  [keys in DialogSize]: object
} = {
  [DialogSize.AUTO]: {
    maxWidth: { sm: "100vw", md: "calc(100vw - 64px)" },
    maxHeight: { sm: "100vh", md: "calc(100vh - 64px)" },
  },
  [DialogSize.SMALL]: {
    width: { xs: "100vw", sm: "75vw", md: "60vw", lg: "30vw" },
    maxHeight: { xs: "100vh", sm: "calc(100vh - 64px)" },
  },
  [DialogSize.NORMAL]: {
    width: { xs: "100vw", sm: "96vw", md: "75vw", lg: "60vw" },
    maxHeight: { xs: "100vh", sm: "calc(100vh - 64px)" },
  },
  [DialogSize.LARGE]: {
    margin: { sm: 0, md: "32px" },
    width: { sm: "100vw", md: "96vw" },
    maxHeight: { sm: "100vh", md: "calc(100vh - 64px)" },
  },
  [DialogSize.FULLSCREEN]: {},
}

interface MLUDialogProps {
  children: React.ReactNode
  open: boolean
  actions?: React.ReactNode[]
  className?: string
  color?: string
  icon?: React.ReactNode
  actionsAlignment?: ActionsAlignment
  loading?: boolean
  size?: DialogSize
  title?: string
  onClose?: () => void
}

const MLUDialog = ({
  children,
  open,
  actions,
  className,
  color,
  icon,
  actionsAlignment = ActionsAlignment.RIGHT,
  loading = false,
  size = DialogSize.NORMAL,
  title,
  onClose,
}: MLUDialogProps) => {
  const styles = (theme: Theme) => {
    const customColor =
      color && color in theme.palette
        ? (theme.palette[color as keyof Palette] as PaletteColor).main
        : color
    return {
      ".MuiDialog-paper": {
        ...dialogSizes[size],
      },
      ".MuiDialogTitle-root": {
        borderBottomColor: customColor,
      },
      ".MLUDialogTitle-icon": {
        color: customColor,
        marginRight: "10px",
      },
      ".MuiDialogActions-root": {
        justifyContent: actionsAlignment.toString(),
      },
    }
  }
  return (
    <Dialog
      open={open}
      className={className}
      fullScreen={size === DialogSize.FULLSCREEN}
      sx={styles}>
      {loading && <MLUProgressBar />}
      {title && (
        <DialogTitle variant="h2">
          {icon && <span className="MLUDialogTitle-icon">{icon}</span>}
          {title}
        </DialogTitle>
      )}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

export default MLUDialog
