import * as React from "react"
import PropTypes from "prop-types"
import { all, any } from "ramda"

/**
 * Transform plain text new line char to html BR
 *
 * @param  {string}  input  Plain text
 *
 * @return {string}
 */
export const nlToBR = (input) =>
  input
    .split("\n")
    .map((item) => `${item}<br/>`)
    .join("")

/**
 * { item_description }
 */
export const listShape = {
  items: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,

  creatingItem: PropTypes.object.isRequired,

  updatingIds: PropTypes.array.isRequired,
  deletingIds: PropTypes.array.isRequired,
  lastLoadAt: PropTypes.string,

  isLoading: PropTypes.bool.isRequired,
  isReloading: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
}

// TODO: Add support for React.Fragment
export const isOfComponentType = (types) => (child) =>
  Array.isArray(child)
    ? all(isOfComponentType(types))(child)
    : any(
        (typesHasElm) =>
          child.type.prototype instanceof typesHasElm ||
          child.type === typesHasElm,
      )(types)

export const oneOfComponentType =
  ({ types, errorMessage }) =>
  (props) => {
    if (
      all(
        (_) => _ === true,
        React.Children.map(props.children, isOfComponentType(types)),
      )
    ) {
      return null
    }

    return new Error(errorMessage)
  }

export const usePrevious = (value) => {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
