import { mixpanelTrack } from "lib/core/mixpanel"
import { getWordCount } from "lib/core/utils"
import { getPageName } from "lib/core/router.utils"

export const trackSupportDialogOpen = (match) =>
  mixpanelTrack("Support Dialog - Open", {
    location: getPageName(match.path),
  })

export const trackClickTutorialLink = (location, name) =>
  mixpanelTrack("Support Dialog - Click Link", { location, name })

export const trackGoToForm = () => mixpanelTrack("Support Dialog - Go To Form")

export const trackSendForm = (
  content,
  ticketLabel,
  attachmentCount,
  attachmentTypes,
  screenShotStatus,
) =>
  mixpanelTrack("Support Dialog - Send Form", {
    length_words: getWordCount(content),
    form_topic: ticketLabel,
    attachment_count: attachmentCount,
    attachment_types: attachmentTypes,
    screenshot_status: screenShotStatus,
  })
