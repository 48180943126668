import { combineReducers } from "redux"
import {
  DOORBEL_SUBMIT_LOADING,
  DOORBEL_SUBMIT_ERROR,
  DOORBEL_SUBMIT_SUCCESS,
  DOORBEL_OPEN_ERROR,
  DOORBEL_OPEN_SUCCESS,
} from "entities/doorbell/doorbell.actions"

/**
 * Manage slice: state.doorbell.submit
 *
 * @param  {Array}   state         Default slice state
 * @param  {Object}  arg2          Action object
 * @param  {string}  arg2.type     Action type
 * @param  {Object}  arg2.payload  API response
 *
 * @return {Object}  New state for state.doorbell.submit
 */
const submit = (
  state = {
    data: {
      body: null,
      ok: null,
    },
    isLoading: false,
  },
  { type, payload },
) => {
  switch (type) {
    case DOORBEL_SUBMIT_ERROR:
    case DOORBEL_SUBMIT_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      }
    case DOORBEL_SUBMIT_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      }
    default:
      return state
  }
}

/**
 * Manage slice: state.doorbell.open
 *
 * @param  {Array}   state         Default slice state
 * @param  {Object}  arg2          Action object
 * @param  {string}  arg2.type     Action type
 * @param  {Object}  arg2.payload  API response
 *
 * @return {Object}  New state for state.doorbell.open
 */
const open = (state = null, { type, payload }) => {
  switch (type) {
    case DOORBEL_OPEN_ERROR:
    case DOORBEL_OPEN_SUCCESS:
      return payload
    default:
      return state
  }
}

/**
 * State shape:
 *
 * state.doorbell = {
 *     submit,
 *     open
 * }
 */
export const doorbellReducers = {
  doorbell: combineReducers({
    submit,
    open,
  }),
}
