import { SerializedError, createReducer } from "@reduxjs/toolkit"

import {
  activate,
  deactivate,
  reloadStatus,
  verify,
} from "./multifactor-auth-app.actions"
import { LoadingStatus } from "lib/leeruniek/interfaces"

export interface MultiFactorAuthAppState {
  activate: {
    loadingStatus: LoadingStatus
    uri: string | null
    error: SerializedError | null
  }

  deactivate: {
    loadingStatus: LoadingStatus
    error: SerializedError | null
  }

  status: {
    loadingStatus: LoadingStatus
    isActive: boolean
    error: SerializedError | null
  }

  verify: {
    loadingStatus: LoadingStatus
    isVerified: boolean
    error: SerializedError | null
  }
}

export const initialState: MultiFactorAuthAppState = {
  activate: {
    loadingStatus: LoadingStatus.IDLE,
    uri: null,
    error: null,
  },

  deactivate: {
    loadingStatus: LoadingStatus.IDLE,
    error: null,
  },

  status: {
    loadingStatus: LoadingStatus.IDLE,
    isActive: false,
    error: null,
  },

  verify: {
    loadingStatus: LoadingStatus.IDLE,
    isVerified: false,
    error: null,
  },
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(activate.pending, (state) => {
      state.activate = {
        loadingStatus: LoadingStatus.PENDING,
        uri: null,
        error: null,
      }
    })
    .addCase(activate.fulfilled, (state, action) => {
      state.activate = {
        loadingStatus: LoadingStatus.SUCCEEDED,
        uri: action.payload,
        error: null,
      }
    })
    .addCase(activate.rejected, (state, action) => {
      state.activate = {
        loadingStatus: LoadingStatus.FAILED,
        uri: null,
        error: action.error,
      }
    })
    .addCase(deactivate.pending, (state) => {
      state.deactivate = {
        loadingStatus: LoadingStatus.PENDING,
        error: null,
      }
    })
    .addCase(deactivate.fulfilled, (state) => {
      state.deactivate = {
        loadingStatus: LoadingStatus.SUCCEEDED,
        error: null,
      }
    })
    .addCase(deactivate.rejected, (state, action) => {
      state.deactivate = {
        loadingStatus: LoadingStatus.FAILED,
        error: action.error,
      }
    })
    .addCase(reloadStatus.pending, (state) => {
      state.status = {
        ...state.status,
        loadingStatus: LoadingStatus.PENDING,
        error: null,
      }
    })
    .addCase(reloadStatus.fulfilled, (state, action) => {
      state.status = {
        loadingStatus: LoadingStatus.SUCCEEDED,
        isActive: action.payload === "active",
        error: null,
      }
    })
    .addCase(reloadStatus.rejected, (state, action) => {
      state.status = {
        ...state.status,
        loadingStatus: LoadingStatus.FAILED,
        error: action.error,
      }
    })
    .addCase(verify.pending, (state) => {
      state.verify = {
        loadingStatus: LoadingStatus.PENDING,
        isVerified: false,
        error: null,
      }
    })
    .addCase(verify.fulfilled, (state, action) => {
      state.verify = {
        loadingStatus: LoadingStatus.SUCCEEDED,
        isVerified: action.payload,
        error: null,
      }
    })
    .addCase(verify.rejected, (state, action) => {
      state.verify = {
        loadingStatus: LoadingStatus.FAILED,
        isVerified: false,
        error: action.error,
      }
    })
})
