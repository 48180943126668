import { GET, POST } from "lib/core/api"

/**
 * Fetches the current logged in user (user_id from the JWT)
 *
 * @return {Promise}
 */
export const fetchCurrent = () => GET("/profile/")

/**
 * { lambda_description }
 *
 * @param  {Object}   arg1           Payload
 * @param  {string}   arg1.username  The username
 * @param  {string}   arg1.password  The password
 *
 * @return {Promise}
 */
export const login = ({ username, password, multiFactorAuthAppCode }) =>
  POST("/login/", {
    body: {
      username,
      password,
      totpChallenge: multiFactorAuthAppCode,
    },
  })
