import { POST } from "lib/core/api"

const DOORBELL_APP_URL = `${WEBP_DOORBELL_API_URL}/applications/${WEBP_DOORBELL_APP_ID}`

/**
 * Send `open` request to doorbell
 *
 * @return {Promise}
 */
export const open = () => {
  return POST(
    `${DOORBELL_APP_URL}/open`,
    {
      query: {
        key: WEBP_DOORBELL_APP_KEY,
      },
    },
    {
      trailingSlash: false,
    },
  )
}

/**
 * Send `submit` request to doorbell
 *
 * @param  {Object}   arg1             Data
 * @param  {string}   arg1.email       User email
 * @param  {string}   arg1.message     User message
 * @param  {string}   arg1.name        User name
 * @param  {string}   arg1.screenshot  Screen image data
 * @param  {Object}   arg1.properties  Other data
 *
 * @return {Promise}
 */
export const submit = ({
  email,
  message,
  name,
  screenshot,
  properties,
  attachments,
}) =>
  POST(
    `${DOORBELL_APP_URL}/submit`,
    {
      body: {
        // required
        email,
        message,

        // optional
        screenshot,
        name,
        properties,
        attachments,
      },
      query: {
        key: WEBP_DOORBELL_APP_KEY,
      },
    },
    {
      trailingSlash: false,
    },
  )

/*
In order to submit ticket to doorbell with attachments, we first need to upload
our resources to /upload endpoint that returns an array of attachents' ids

Then, this list has to be added to /submit endpoint
*/
export const uploadFilesToDoorbell = async (files) => {
  const filesAsArray = Array.from(files)

  const formDataToSend = new FormData()

  for (let i = 0; i < filesAsArray.length; i++) {
    formDataToSend.append(
      `leeruniek-support-file-upload-${i}`,
      filesAsArray[i],
      filesAsArray[i].name,
    )
  }

  const responseFromDoorbellAPI = await fetch(
    `${DOORBELL_APP_URL}/upload?key=${WEBP_DOORBELL_APP_KEY}`,
    {
      method: "POST",
      body: formDataToSend,
    },
  )

  const idsOfUploadedFiles = await responseFromDoorbellAPI.json()

  return idsOfUploadedFiles
}
