import { createAsyncThunk } from "@reduxjs/toolkit"
import { GET } from "lib/core/api"

const BASE_PATH = "/otp/totp/"

enum multiFactorAuthAppStatus {
  Active = "active",
  Inactive = "inactive",
}

export const activate = createAsyncThunk(
  "multiFactorAuthApp/activate",
  async () => {
    const response: { uri: string } = await GET(`${BASE_PATH}activate`)
    return response.uri
  },
)

export const deactivate = createAsyncThunk(
  "multiFactorAuthApp/deactivate",
  async (code: string) => {
    const response: { result: boolean } = await GET(
      `${BASE_PATH}deactivate/${code}`,
    )
    return response.result
  },
)

export const verify = createAsyncThunk(
  "multiFactorAuthApp/verify",
  async (code: string) => {
    const response: { result: boolean } = await GET(
      `${BASE_PATH}verify/${code}`,
    )
    return response.result
  },
)

export const reloadStatus = createAsyncThunk(
  "multiFactorAuthApp/reloadStatus",
  async () => {
    const response: { status: multiFactorAuthAppStatus } = await GET(
      `${BASE_PATH}`,
    )
    return response.status
  },
)
