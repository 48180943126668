import { GET } from "../../lib/core/api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { serializeError } from "lib/core/redux-tools.utils"
import { MethodToSubjectDict } from "lib/leeruniek/interfaces"

export const getMethods = createAsyncThunk<
  MethodToSubjectDict,
  { subjectId: number; yearclassId: number }
>(
  "GET_METHODS",
  async () => {
    const result = await GET("/methods/")

    return result as MethodToSubjectDict
  },
  { serializeError },
)
