import { GET, POST, PATCH, DELETE } from "lib/core/api"

export const deleteCategory = (categoryId) =>
  DELETE(`/note_categories/${categoryId}/`)

/**
 * { lambda_description }
 *
 * @param  {string}  name     The name
 * @param  {string}  layer    The layer
 * @param  {integer} schoolId The school ID
 *
 * @return {Promise}
 */
export const create = ({ name, layer, schoolId = null }) =>
  POST("/note_categories/", {
    body: {
      name,
      layer,
      schoolId,
    },
  })

/**
 * { lambda_description }
 *
 * @param  {integer}  pupilId  The pupil identifier
 * @param  {integer}  schoolId  The school identifier
 *
 * @return {Promise}
 */
export const findAllAutocomplete = (pupilId, schoolId) =>
  GET("/note_categories/", {
    query: {
      layer: "PUPIL",
      "~pupil_notes__pupil_id": pupilId,
      school_id: schoolId,
      "include[]": "id,name",
    },
  })

/**
 * { lambda_description }
 *
 * @param  {integer}        id           The identifier
 * @param  {Object}         arg2         The argument 2
 * @param  {Array[string]}  arg2.fields  The fields
 *
 * @return {Promise}
 */
export const findById = (id, { fields = [] } = {}) =>
  GET(`/note_categories/${id}/`, {
    query: {
      "include[]": fields.join(","),
    },
  })

/**
 * { lambda_description }
 *
 * @param  {integer[]}  ids    The identifiers
 * @param  {Object}     query  The query
 *
 * @return {undefined}  { description_of_the_return_value }
 */
export const findAllById = (ids, { fields = [] } = {}) =>
  GET("/note_categories/", {
    query: {
      "include[]": fields.join(","),
      "id[]": ids.join(","),
    },
  })

/**
 * { lambda_description }
 *
 * @param  {integer}  id         The identifier
 * @param  {Object}   arg2       The argument 2
 * @param  {string}   arg2.name  The name
 *
 * @return {Promise}
 */
export const update = (id, { name }) =>
  PATCH(`/note_categories/${id}`, {
    body: {
      name,
    },
  })
