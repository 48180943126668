import { createReducer, SerializedError } from "@reduxjs/toolkit"
import {
  NoteCategory,
  NoteCategoryAutocomplete,
} from "entities/note-categories/note-categories.interfaces"
import { GroupPlanNote } from "entities/group-plans/group-plans.interfaces"
import {
  getNoteCategories,
  createNoteCategory,
  updateNoteCategory,
  updateNoteCategoryInState,
  deleteNoteCategory,
  getNotes,
  createNote,
  updateNote,
  updateNoteInState,
  deleteNote,
  getCategoriesAutocomplete,
  getSubcategoriesAutocomplete,
} from "entities/groups__worksheet.page/plans-selected.section/plans-selected.actions"

interface State {
  noteCategories: NoteCategory[]
  notes: GroupPlanNote[]
  categoriesAutocomplete: NoteCategoryAutocomplete[]
  subcategoriesAutocomplete: NoteCategoryAutocomplete[]
  noteCategoriesUpdatingIds: number[]
  noteCategoriesDeletingIds: number[]
  notesUpdatingIds: number[]
  notesDeletingIds: number[]
  isNoteCategoriesLoading: boolean
  isNoteCategoriesCreating: boolean
  isNotesLoading: boolean
  isNotesCreating: boolean
  isCategoriesAutocompleteLoading: boolean
  hasCategoriesAutocompleteLoadedOnce: boolean
  isSubcategoriesAutocompleteLoading: boolean
  hasSubcategoriesAutocompleteLoadedOnce: boolean
  error: SerializedError | null
}

const initialState: State = {
  noteCategories: [],
  notes: [],
  categoriesAutocomplete: [],
  subcategoriesAutocomplete: [],
  noteCategoriesUpdatingIds: [],
  noteCategoriesDeletingIds: [],
  notesUpdatingIds: [],
  notesDeletingIds: [],
  isNoteCategoriesLoading: false,
  isNoteCategoriesCreating: false,
  isNotesLoading: false,
  isNotesCreating: false,
  isCategoriesAutocompleteLoading: false,
  hasCategoriesAutocompleteLoadedOnce: false,
  isSubcategoriesAutocompleteLoading: false,
  hasSubcategoriesAutocompleteLoadedOnce: false,
  error: null,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(getNoteCategories.pending, (state) => {
    state.isNoteCategoriesLoading = true
  })
  builder.addCase(getNoteCategories.fulfilled, (state, action) => {
    state.isNoteCategoriesLoading = false
    state.noteCategories = action.payload
  })
  builder.addCase(getNoteCategories.rejected, (state, action) => {
    state.isNoteCategoriesLoading = false
    state.error = action.error
  })
  builder.addCase(createNoteCategory.pending, (state) => {
    state.isNoteCategoriesCreating = true
  })
  builder.addCase(createNoteCategory.fulfilled, (state, action) => {
    state.isNoteCategoriesCreating = false
    state.noteCategories.push(action.payload)
  })
  builder.addCase(createNoteCategory.rejected, (state, action) => {
    state.isNoteCategoriesCreating = false
    state.error = action.error
  })
  builder.addCase(updateNoteCategory.pending, (state, action) => {
    state.noteCategoriesUpdatingIds.push(action.meta.arg.id)
  })
  builder.addCase(updateNoteCategory.fulfilled, (state, action) => {
    state.noteCategoriesUpdatingIds = state.noteCategoriesUpdatingIds.filter(
      (id) => id !== action.meta.arg.id,
    )
    const updatedIndex = state.noteCategories.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (updatedIndex !== -1) {
      state.noteCategories[updatedIndex] = action.payload
    }
  })
  builder.addCase(updateNoteCategory.rejected, (state, action) => {
    state.noteCategoriesUpdatingIds = state.noteCategoriesUpdatingIds.filter(
      (id) => id !== action.meta.arg.id,
    )
    state.error = action.error
  })
  builder.addCase(updateNoteCategoryInState, (state, action) => {
    const updatedIndex = state.noteCategories.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (updatedIndex !== -1) {
      state.noteCategories[updatedIndex] = {
        ...state.noteCategories[updatedIndex],
        ...action.payload.data,
      }
    }
  })
  builder.addCase(deleteNoteCategory.pending, (state, action) => {
    state.noteCategoriesDeletingIds.push(action.meta.arg)
  })
  builder.addCase(deleteNoteCategory.fulfilled, (state, action) => {
    state.noteCategoriesDeletingIds = state.noteCategoriesDeletingIds.filter(
      (id) => id !== action.meta.arg,
    )
    state.noteCategories = state.noteCategories.filter(
      (category) => category.id !== action.payload.id,
    )
  })
  builder.addCase(deleteNoteCategory.rejected, (state, action) => {
    state.noteCategoriesDeletingIds = state.noteCategoriesDeletingIds.filter(
      (id) => id !== action.meta.arg,
    )
    state.error = action.error
  })
  builder.addCase(getNotes.pending, (state) => {
    state.isNotesLoading = true
  })
  builder.addCase(getNotes.fulfilled, (state, action) => {
    state.isNotesLoading = false
    state.notes = action.payload
  })
  builder.addCase(getNotes.rejected, (state, action) => {
    state.isNotesLoading = false
    state.error = action.error
  })
  builder.addCase(createNote.pending, (state) => {
    state.isNotesCreating = true
  })
  builder.addCase(createNote.fulfilled, (state, action) => {
    state.isNotesCreating = false
    state.notes.push(action.payload)
  })
  builder.addCase(createNote.rejected, (state, action) => {
    state.isNotesCreating = false
    state.error = action.error
  })
  builder.addCase(updateNote.pending, (state, action) => {
    state.notesUpdatingIds.push(action.meta.arg.id)
  })
  builder.addCase(updateNote.fulfilled, (state, action) => {
    state.notesUpdatingIds = state.notesUpdatingIds.filter(
      (id) => id !== action.meta.arg.id,
    )
    const updatedIndex = state.notes.findIndex(
      (note) => note.id === action.payload.id,
    )

    if (updatedIndex !== -1) {
      state.notes[updatedIndex] = action.payload
    }
  })
  builder.addCase(updateNote.rejected, (state, action) => {
    state.notesUpdatingIds = state.notesUpdatingIds.filter(
      (id) => id !== action.meta.arg.id,
    )
    state.error = action.error
  })
  builder.addCase(updateNoteInState, (state, action) => {
    const updatedIndex = state.notes.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (updatedIndex !== -1) {
      state.notes[updatedIndex] = {
        ...state.notes[updatedIndex],
        ...action.payload.data,
      }
    }
  })
  builder.addCase(deleteNote.pending, (state, action) => {
    state.notesDeletingIds.push(action.meta.arg)
  })
  builder.addCase(deleteNote.fulfilled, (state, action) => {
    state.notesDeletingIds = state.notesDeletingIds.filter(
      (id) => id !== action.meta.arg,
    )
    state.notes = state.notes.filter((note) => note.id !== action.payload.id)
  })
  builder.addCase(deleteNote.rejected, (state, action) => {
    state.notesDeletingIds = state.notesDeletingIds.filter(
      (id) => id !== action.meta.arg,
    )
    state.error = action.error
  })
  builder.addCase(getCategoriesAutocomplete.pending, (state) => {
    state.isCategoriesAutocompleteLoading = true
  })
  builder.addCase(getCategoriesAutocomplete.fulfilled, (state, action) => {
    state.isCategoriesAutocompleteLoading = false
    state.hasCategoriesAutocompleteLoadedOnce = true
    state.categoriesAutocomplete = action.payload
  })
  builder.addCase(getCategoriesAutocomplete.rejected, (state, action) => {
    state.isCategoriesAutocompleteLoading = false
    state.error = action.error
  })
  builder.addCase(getSubcategoriesAutocomplete.pending, (state) => {
    state.isSubcategoriesAutocompleteLoading = true
  })
  builder.addCase(getSubcategoriesAutocomplete.fulfilled, (state, action) => {
    state.isSubcategoriesAutocompleteLoading = false
    state.hasSubcategoriesAutocompleteLoadedOnce = true
    state.subcategoriesAutocomplete = action.payload
  })
  builder.addCase(getSubcategoriesAutocomplete.rejected, (state, action) => {
    state.isSubcategoriesAutocompleteLoading = false
    state.error = action.error
  })
})
