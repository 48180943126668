import React from "react"
import { IconButton, Snackbar } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import { SNACKBAR_DEFAULT_TIMEOUT_MILLISECONDS } from "lib/leeruniek/constants"

interface MLUSnackbarProps {
  className?: string
  closable?: boolean
  message: string
  onClose: () => void
  open: boolean
  timeout?: number
}

const MLUSnackbar = ({
  className,
  closable = false,
  message,
  onClose,
  open,
  timeout = SNACKBAR_DEFAULT_TIMEOUT_MILLISECONDS,
}: MLUSnackbarProps) => {
  return (
    <Snackbar
      action={
        closable && (
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )
      }
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      autoHideDuration={timeout}
      className={className}
      message={message}
      onClose={onClose}
      open={open}
    />
  )
}

export default MLUSnackbar
