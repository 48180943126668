import { combineReducers } from "redux"
import {
  listDefaultState,
  listCreateStart,
  listCreateEnd,
  listLoadStart,
  listLoadEnd,
  listUpdateStart,
  listUpdateEnd,
  listDeleteStart,
  listDeleteEnd,
} from "lib/core/redux.utils"

import { merge, remove } from "@leeruniek/functies"

import {
  PUPILS_NOTE_CATEGORIES_CREATE_START,
  PUPILS_NOTE_CATEGORIES_CREATE_END,
  PUPILS_NOTE_CATEGORIES_LOAD_START,
  PUPILS_NOTE_CATEGORIES_LOAD_END,
  PUPILS_NOTE_CATEGORIES_UPDATE_START,
  PUPILS_NOTE_CATEGORIES_UPDATE_END,
  PUPILS_NOTE_CATEGORIES_UPDATE_NAME_END,
  PUPILS_NOTE_CATEGORIES_DELETE_START,
  PUPILS_NOTE_CATEGORIES_DELETE_END,
} from "entities/pupils_note-categories/pupils_note-categories.actions"
import {
  NOTE_CATEGORIES_AUTOCOMPLETE_CREATE_START,
  NOTE_CATEGORIES_AUTOCOMPLETE_CREATE_END,
  NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_START,
  NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_END,
  NOTE_CATEGORIES_AUTOCOMPLETE_UPDATE_START,
  NOTE_CATEGORIES_AUTOCOMPLETE_UPDATE_END,
  NOTE_CATEGORIES_AUTOCOMPLETE_DELETE_START,
  NOTE_CATEGORIES_AUTOCOMPLETE_DELETE_END,
} from "entities/note-categories/note-categories.actions"
import {
  NOTES_PUPIL_CREATE_START,
  NOTES_PUPIL_CREATE_END,
  NOTES_PUPIL_LOAD_START,
  NOTES_PUPIL_LOAD_END,
  NOTES_PUPIL_UPDATE_START,
  NOTES_PUPIL_UPDATE_END,
  NOTES_PUPIL_DELETE_START,
  NOTES_PUPIL_DELETE_END,
  NOTES_ARCHIVED_PUPIL_CREATE_START,
  NOTES_ARCHIVED_PUPIL_CREATE_END,
  NOTES_ARCHIVED_PUPIL_LOAD_START,
  NOTES_ARCHIVED_PUPIL_LOAD_END,
  NOTES_ARCHIVED_PUPIL_UPDATE_START,
  NOTES_ARCHIVED_PUPIL_UPDATE_END,
  NOTES_ARCHIVED_PUPIL_DELETE_START,
  NOTES_ARCHIVED_PUPIL_DELETE_END,
} from "entities/notes/notes.actions"
import pupilSocialEmotionalNotesReducer from "entities/pupils/pages/profile/social-emotional/social-emotional.reducer"
import pupilInstructionGroupsReducer from "entities/pupils/pages/profile/instruction-groups/instruction-groups.reducer"

/**
 * Reducer for `state.pupilsProfilePage.notes__Notes`
 *
 * @param  {Object}  state         Slice state
 * @param  {Object}  arg2          Action data
 * @param  {string}  arg2.type     Action name
 * @param  {Object}  arg2.payload  Action payload
 *
 * @return {Object}  New state
 */
const notes = (state = listDefaultState, { type, payload }) => {
  switch (type) {
    case NOTES_PUPIL_CREATE_START:
      return listCreateStart(state, payload)
    case NOTES_PUPIL_CREATE_END:
      return listCreateEnd(state, payload)

    case NOTES_PUPIL_LOAD_START:
      return listLoadStart(state, payload)
    case NOTES_PUPIL_LOAD_END:
      return listLoadEnd(state, payload)

    case NOTES_PUPIL_UPDATE_START:
      return listUpdateStart(state, payload)
    case NOTES_PUPIL_UPDATE_END:
      return listUpdateEnd(state, payload)

    case NOTES_PUPIL_DELETE_START:
      return listDeleteStart(state, payload)
    case NOTES_PUPIL_DELETE_END:
      return listDeleteEnd(state, payload)

    default:
      return state
  }
}

/**
 * Reducer for `state.pupilsProfilePage.notes__Notes`
 *
 * @param  {Object}  state         Slice state
 * @param  {Object}  arg2          Action data
 * @param  {string}  arg2.type     Action name
 * @param  {Object}  arg2.payload  Action payload
 *
 * @return {Object}  New state
 */
const notesArchived = (state = listDefaultState, { type, payload }) => {
  switch (type) {
    case NOTES_ARCHIVED_PUPIL_CREATE_START:
      return listCreateStart(state, payload)
    case NOTES_ARCHIVED_PUPIL_CREATE_END:
      return listCreateEnd(state, payload)

    case NOTES_ARCHIVED_PUPIL_LOAD_START:
      return listLoadStart(state, payload)
    case NOTES_ARCHIVED_PUPIL_LOAD_END:
      return listLoadEnd(state, payload)

    case NOTES_ARCHIVED_PUPIL_UPDATE_START:
      return listUpdateStart(state, payload)
    case NOTES_ARCHIVED_PUPIL_UPDATE_END:
      return listUpdateEnd(state, payload)

    case NOTES_ARCHIVED_PUPIL_DELETE_START:
      return listDeleteStart(state, payload)
    case NOTES_ARCHIVED_PUPIL_DELETE_END:
      return listDeleteEnd(state, payload)

    default:
      return state
  }
}

/**
 * Reducer for `state.pupilsProfilePage.notes__Categories`
 *
 * @param  {Object}  state         Slice state
 * @param  {Object}  arg2          Action data
 * @param  {string}  arg2.type     Action name
 * @param  {Object}  arg2.payload  Action payload
 *
 * @return {Object}  New state
 */
const categories = (state = listDefaultState, { type, payload }) => {
  switch (type) {
    case PUPILS_NOTE_CATEGORIES_CREATE_START:
      return listCreateStart(state, payload)
    case PUPILS_NOTE_CATEGORIES_CREATE_END:
      return listCreateEnd(state, payload)

    case PUPILS_NOTE_CATEGORIES_LOAD_START:
      return listLoadStart(state, payload)
    case PUPILS_NOTE_CATEGORIES_LOAD_END:
      return listLoadEnd(state, payload)

    case PUPILS_NOTE_CATEGORIES_UPDATE_START:
      return listUpdateStart(state, payload)
    case PUPILS_NOTE_CATEGORIES_UPDATE_END:
      return listUpdateEnd(state, payload)
    case PUPILS_NOTE_CATEGORIES_UPDATE_NAME_END:
      return {
        ...state,
        updatingIds: remove(payload.updatedCategoryId)(state.updatingIds),
        items: state.items.map((element) => {
          if (element.noteCategoryId !== payload.updatedCategoryId) {
            return element
          }
          const newCategory = merge(
            element.noteCategory,
            payload.updatedCategory,
          )
          return { ...element, noteCategory: newCategory }
        }),
      }
    case PUPILS_NOTE_CATEGORIES_DELETE_START:
      return listDeleteStart(state, payload)
    case PUPILS_NOTE_CATEGORIES_DELETE_END:
      return listDeleteEnd(state, payload)

    default:
      return state
  }
}

/**
 * Reducer for `state.pupilsProfilePage.notes__CategoriesAutocomplete`
 *
 * @param  {Object}  state         Slice state
 * @param  {Object}  arg2          Action data
 * @param  {string}  arg2.type     Action name
 * @param  {Object}  arg2.payload  Action payload
 *
 * @return {Object}  New state
 */
const categoriesAutocomplete = (
  state = listDefaultState,
  { type, payload },
) => {
  switch (type) {
    case NOTE_CATEGORIES_AUTOCOMPLETE_CREATE_START:
      return listCreateStart(state, payload)
    case NOTE_CATEGORIES_AUTOCOMPLETE_CREATE_END:
      return listCreateEnd(state, payload)

    case NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_START:
      return listLoadStart(state, payload)
    case NOTE_CATEGORIES_AUTOCOMPLETE_LOAD_END:
      return listLoadEnd(state, payload)

    case NOTE_CATEGORIES_AUTOCOMPLETE_UPDATE_START:
      return listUpdateStart(state, payload)
    case NOTE_CATEGORIES_AUTOCOMPLETE_UPDATE_END:
      return listUpdateEnd(state, payload)

    case NOTE_CATEGORIES_AUTOCOMPLETE_DELETE_START:
      return listDeleteStart(state, payload)
    case NOTE_CATEGORIES_AUTOCOMPLETE_DELETE_END:
      return listDeleteEnd(state, payload)

    default:
      return state
  }
}

export const pupilsProfilePageReducers = {
  pupilsProfilePage: combineReducers({
    notes__Notes: notes,
    notes__NotesArchived: notesArchived,
    notes__Categories: categories,
    notes__CategoriesAutocomplete: categoriesAutocomplete,
    socialEmotionalNotes: pupilSocialEmotionalNotesReducer,
    instructionGroups: pupilInstructionGroupsReducer,
  }),
}
