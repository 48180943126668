import React from "react"
import { components } from "react-select"
import css from "components/select/css/select.module.scss"

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={css.dropdownIndicator} />
    </components.DropdownIndicator>
  )
}
