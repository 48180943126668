import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { GET, POST, PATCH, DELETE } from "lib/core/api"
import { serializeError } from "lib/core/redux-tools.utils"
import {
  GroupPlan,
  GroupPlanNote,
  GroupPlanTemplate,
} from "entities/group-plans/group-plans.interfaces"
import { NoteCategory } from "entities/note-categories/note-categories.interfaces"

export const getGroupPlans = createAsyncThunk<
  GroupPlan[],
  { subjectId: number; yearclassId: number }
>(
  "groupPlans/getGroupPlan",
  async (args) => {
    const result = await GET("/group_plans", {
      query: {
        subject_id: args.subjectId,
        yearclass_id: args.yearclassId,
      },
    })

    return result as GroupPlan[]
  },
  { serializeError },
)

export const createGroupPlan = createAsyncThunk<
  GroupPlan,
  {
    name: string
    subjectId: number
    yearclassId: number
    clonedFromId?: number
  }
>(
  "groupPlans/createGroupPlan",
  async (data) => {
    const result = await POST("/group_plans/", {
      body: {
        name: data.name,
        subject_id: data.subjectId,
        yearclass_id: data.yearclassId,
        group_plan_id: data.clonedFromId,
      },
    })

    return result as GroupPlan
  },
  { serializeError },
)

export const updateGroupPlan = createAsyncThunk<
  GroupPlan,
  { id: number; data: Partial<GroupPlan> }
>(
  "groupPlans/updateGroupPlan",
  async (args) => {
    const result = await PATCH(`/group_plans/${args.id}`, args.data)

    return result as GroupPlan
  },
  { serializeError },
)

export const deleteGroupPlan = createAsyncThunk<{ id: number }, number>(
  "groupPlans/deleteGroupPlan",
  async (id) => {
    const result = await DELETE(`/group_plans/${id}`).then(() => ({ id }))

    return result as { id: number }
  },
  { serializeError },
)

export const getClonableGroupPlans = createAsyncThunk<
  GroupPlan[],
  { subjectId: number; yearclassId: number }
>(
  "groupPlans/getClonableGroupPlans",
  async (args) => {
    const result = await GET("/recent_plans", {
      query: {
        yearclass_id: args.yearclassId,
        subject_id: args.subjectId,
        "include[]": [
          "id",
          "name",
          "yearclassId",
          "yearclassName",
          "yearclassCalendarYear",
        ],
      },
    })

    return result as GroupPlan[]
  },
  { serializeError },
)

export const getGroupPlanTemplates = createAsyncThunk<
  GroupPlanTemplate[],
  { schoolId: number; subjectId: number; grade: number }
>(
  "groupPlans/getGroupPlanTemplates",
  async (args) => {
    const result = await GET("/group_plan_templates/", {
      query: {
        subject_id: args.subjectId,
        school_id: args.schoolId,
        "include[]": "id,name,is_system",
        is_published: true,
        grade: !isNaN(args.grade) ? args.grade : undefined,
      },
    })

    return result as GroupPlanTemplate[]
  },
  { serializeError },
)

export const getGroupPlanNoteCategoriesPreview = createAsyncThunk<
  NoteCategory[],
  number
>(
  "groupPlans/getGroupPlanNoteCategoriesPreview",
  async (planId) => {
    const result = await GET("/note_categories", {
      query: {
        group_plan_id: planId,
        "include[]": "id,name,parent_note_category_id,position",
      },
    })

    return result as NoteCategory[]
  },
  { serializeError },
)

export const clearGroupPlanNoteCategoriesPreview = createAction(
  "groupPlans/clearGroupPlanNoteCategoriesPreview",
)

export const getGroupPlanNotesPreview = createAsyncThunk<
  GroupPlanNote[],
  number
>(
  "groupPlans/getGroupPlanNotesPreview",
  async (planId) => {
    const result = await GET("/group_plan_note", {
      query: {
        category__group_plan_id: planId,
        category__is_archived: false,
        date_archived__isnull: true,
        "exclude[]": "pupil_id",
      },
    })

    return result as GroupPlanNote[]
  },
  { serializeError },
)

export const clearGroupPlanNotesPreview = createAction(
  "groupPlans/clearGroupPlanNotesPreview",
)
