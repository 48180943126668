import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { sanitize } from "dompurify"
import { connect } from "react-redux"
import { isEmpty } from "ramda"

import { login } from "entities/sessions/sessions.actions"

import { LUCheckbox, LUMessage, LUInput } from "webclient-ui/components"

import { t } from "entities/account__login.page/account__login.i18n"
import css from "entities/account__login.page/account__login.module.scss"
import {
  CODE_LENGTH,
  MultiFactorAuthAppCodeInput,
} from "entities/multifactor-auth-app/multifactor-auth-app-code-input"
import { mixpanelTrack } from "lib/core/mixpanel"
import MLUButton from "components/ui/mlubutton/mlubutton"

const LoginStep = {
  Password: "password",
  MultiFactorAuthApp: "multifactor-auth-app",
}

@connect(
  (store) => ({
    isLoading: store.session.isWaitingForLogin,
    hasErrors: store.session.hasErrors,
    hasNoConnectedPayingSchoolError:
      store.session.hasNoConnectedPayingSchoolError,
    isMultiFactorAuthAppRequired: store.session.isMultiFactorAuthAppRequired,
    isMultiFactorAuthAppCodeInvalid:
      store.session.isMultiFactorAuthAppCodeInvalid,
  }),
  {
    login,
  },
)
export default class AccountLoginPage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    hasErrors: PropTypes.bool,
  }

  // Defaults for non-required props
  static defaultProps = {
    isLoading: false,
    hasErrors: false,
  }

  // Initial component state
  state = {
    username: "",
    password: "",
    multiFactorAuthAppCode: undefined,
    rememberMe: true,
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    const {
      isLoading,
      hasErrors,
      hasNoConnectedPayingSchoolError,
      isMultiFactorAuthAppRequired,
      isMultiFactorAuthAppCodeInvalid,
    } = this.props
    const { username, password, rememberMe, multiFactorAuthAppCode } =
      this.state

    const cantLogin = isEmpty(username) || isEmpty(password)
    const cantVerify = multiFactorAuthAppCode?.length !== CODE_LENGTH
    const loginStep =
      !isMultiFactorAuthAppRequired && !isMultiFactorAuthAppCodeInvalid
        ? LoginStep.Password
        : LoginStep.MultiFactorAuthApp

    return (
      <div className={css["login-wrapper"]} data-testid={"user_login_page"}>
        <div className={cx("animated", "fadeIn", css.login)}>
          <div className={css.login__logo} />
          {loginStep === LoginStep.MultiFactorAuthApp ? (
            <div className={css.login__form}>
              <h1 className={css.login__title}>{t("activation-code")}</h1>
              <div
                className={
                  css["login__multifactor-auth-app-verification-container"]
                }>
                <div
                  className={css["login__multifactor-auth-app-guide"]}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(t("login__mfa-guide")),
                  }}
                />
                <div
                  className={
                    css["login__multifactor-auth-app-verification-input"]
                  }>
                  <MultiFactorAuthAppCodeInput
                    code={multiFactorAuthAppCode}
                    isDisabled={isLoading}
                    onCodeChange={this.handleMultiFactorAuthAppCodeBind}
                    onSubmit={this.handleVerify}
                  />
                </div>
                {isMultiFactorAuthAppCodeInvalid && (
                  <div className={css["login__multifactor-auth-app-error"]}>
                    {t("login__error__invalid-multi-factor-auth-code")}
                  </div>
                )}
                <a
                  className={css["login__multifactor-auth-app-link"]}
                  href={t("doorbell_multifactor_auth_app_link_href")}
                  target="_blank"
                  rel="noreferrer noopener">
                  {t("more_information")}
                </a>
              </div>
              <div
                className={css["login__submit-wrapper"]}
                data-testid="login_page__login-button">
                <MLUButton
                  icon={
                    <i
                      className={
                        isLoading ? "fa fa-spinner fa-spin" : "fa fa-lock"
                      }
                    />
                  }
                  disabled={cantVerify || isLoading}
                  label={t("verify")}
                  onClick={this.handleLogin}
                />
              </div>
            </div>
          ) : (
            <div className={css.login__form}>
              {hasErrors ? (
                <LUMessage className={css.login__error} type="error">
                  {hasNoConnectedPayingSchoolError ? (
                    <p data-testid="school_not_paying_error">
                      {t("login__error__school-not-paying")}
                    </p>
                  ) : (
                    <React.Fragment>
                      {t("login__error")}

                      <br />
                      <br />

                      <p>
                        <strong>
                          {t("login__error__forgot-password-question")}
                        </strong>
                        <br />
                        {t("login__error__forgot-password-advice")}
                      </p>

                      <p>
                        <strong>
                          {t("login__error__no-username-question")}
                        </strong>
                        <br />
                        {t("login__error__no-username-advice")}
                      </p>
                    </React.Fragment>
                  )}
                </LUMessage>
              ) : null}
              <h1 className={css.login__title}>{t("login")}</h1>
              <LUInput
                className={css.login_field}
                placeholder={t("login__username-placeholder")}
                value={username}
                hasAutoFocus={true}
                hasBar={false}
                isDisabled={isLoading}
                onChange={this.handleUsernameBind}
                onKeyPress={this.handleFieldKeyPress}
                name="username"
              />
              <LUInput
                className={css.login_field}
                placeholder={t("login__password-placeholder")}
                value={password}
                type="password"
                hasBar={false}
                isDisabled={isLoading}
                onChange={this.handlePasswordBind}
                onKeyPress={this.handleFieldKeyPress}
                name="password"
              />
              <a
                className={css["login__forgot-password"]}
                href="/accounts/password_reset/">
                {t("login__forgot-password")}
              </a>
              <div
                className={css["login__submit-wrapper"]}
                data-testid="login_page__login-button">
                <MLUButton
                  icon={
                    <i
                      className={
                        isLoading ? "fa fa-spinner fa-spin" : "fa fa-lock"
                      }
                    />
                  }
                  disabled={cantLogin || isLoading}
                  label={t("login")}
                  onClick={this.handleLogin}
                />
              </div>
              <LUCheckbox
                className={css["login__remember-me"]}
                label={t("login__remember-me")}
                isChecked={rememberMe}
                isDisabled={isLoading}
                onChange={this.handleRememberMeBind}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  componentDidUpdate = (prevProps) => {
    if (
      !prevProps.isMultiFactorAuthAppCodeInvalid &&
      this.props.isMultiFactorAuthAppCodeInvalid
    ) {
      this.setState({ multiFactorAuthAppCode: "" })
      mixpanelTrack("user-login", {
        result: "invalid-mfa-code",
      })
    }
  }

  /**
   * Bind state.username to input
   *
   * @param  {string}  value  Input value
   *
   * @return {undefined}
   */
  handleUsernameBind = (value) => {
    this.setState({
      username: value,
    })
  }

  /**
   * Bind state.password to input
   *
   * @param  {string}  value  Input value
   *
   * @return {undefined}
   */
  handlePasswordBind = (value) => {
    this.setState({
      password: value,
    })
  }

  /**
   * Bind state.rememberMe to checkbox
   *
   * @param  {boolean}  value  Check status
   *
   * @return {undefined}
   */
  handleRememberMeBind = (event) => {
    this.setState({
      rememberMe: event.currentTarget.checked,
    })
  }

  handleMultiFactorAuthAppCodeBind = (value) => {
    this.setState({
      multiFactorAuthAppCode: value,
    })
  }

  /**
   * Username & Password fields on Enter do Login
   *
   * @param  {Object}  event  The event
   */
  handleFieldKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleLogin()
    }
  }

  /**
   * { function_description }
   *
   * @param  {Object}  event  The event
   *
   * @return {undefined}
   */
  handleLogin = () => {
    const { username, password, rememberMe, multiFactorAuthAppCode } =
      this.state

    this.props.login({
      username,
      password,
      rememberMe,
      multiFactorAuthAppCode,
    })
  }

  handleVerify = () => {
    const { multiFactorAuthAppCode } = this.state
    if (multiFactorAuthAppCode?.length === CODE_LENGTH) {
      this.handleLogin()
    }
  }
}
