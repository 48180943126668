import { createAction } from "@reduxjs/toolkit"
import { GET, POST, PATCH, DELETE } from "lib/core/api"
import { createAsyncAction } from "lib/core/redux.utils"
import { TEST_TYPES } from "lib/leeruniek/constants"

export const getPupil = createAsyncAction(
  "GET_PUPIL",
  (id, schoolId) => {
    return GET(`/pupils/${id}/`, { query: { school_id: schoolId } })
  },
  true,
)

export const createOrUpdatePupilObservation = createAsyncAction(
  "UPDATE_PUPIL_OBSERVATION",
  (pupilObservation) =>
    POST("/social_emotional_observation/", pupilObservation),
)

export const noteTypeMapping = {
  [TEST_TYPES.NATIONAL]: {
    url: "/cito_score_note",
    category: "cito_score",
    scoreIdKey: "citoTestScoreId",
    scoresStoreKey: "scores",
  },
  [TEST_TYPES.METHOD]: {
    url: "/method_score_note",
    category: "method_score",
    scoreIdKey: "methodTestScoreId",
    scoresStoreKey: "methodScores",
  },
}

export const createPupilScoreNote = (testType) => {
  return createAsyncAction(
    `CREATE_PUPIL_${testType.toUpperCase()}_SCORE_NOTE`,
    (scoreId, content, forwardLooking) =>
      POST(noteTypeMapping[testType]["url"], {
        category: noteTypeMapping[testType]["category"],
        [noteTypeMapping[testType]["scoreIdKey"]]: scoreId,
        content,
        ...(testType === TEST_TYPES.NATIONAL ? { forwardLooking } : {}),
      }),
  )
}

export const updatePupilScoreNote = (scoreType) =>
  createAsyncAction(
    `UPDATE_PUPIL_${scoreType.toUpperCase()}_SCORE_NOTE`,
    (id, content) =>
      PATCH(`${noteTypeMapping[scoreType]["url"]}/${id}/`, { content }),
  )

export const deletePupilScoreNote = (scoreType) =>
  createAsyncAction(
    `DELETE_PUPIL_${scoreType.toUpperCase()}_SCORE_NOTE`,
    async (id, scoreId) => {
      await DELETE(`${noteTypeMapping[scoreType]["url"]}/${id}/`)
      return {
        id: id,
        [noteTypeMapping[scoreType]["scoreIdKey"]]: scoreId,
      }
    },
  )

export const incrementRemovedPrintSectionCount = createAction(
  "INCREMENT_REMOVED_PRINT_SECTION_COUNT",
)

export const decrementRemovedPrintSectionCount = createAction(
  "DECREMENT_REMOVED_PRINT_SECTION_COUNT",
)

export const resetRemovedPrintSectionCount = createAction(
  "RESET_REMOVED_PRINT_SECTION_COUNT",
)
