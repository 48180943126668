import { find, path, pipe, hasPath } from "ramda"

import { isString, interpolate } from "lib/core/utils"

/**
 * Translate
 *
 * @param  {string}    language       The language code
 * @param  {Object[]}  languagePacks  Key/Value stores with translations
 * @param  {String}    label          Label we want to translate
 * @param  {Object}    params         Variables for interpolation
 *
 * @return {string}  { description_of_the_return_value }
 */
const translate =
  (language) =>
  (...languagePacks) =>
  (label, params = {}) => {
    const translationPath = [label, language]
    // Walk through all language packs and return the first translation found
    const value = pipe(
      find(
        (languagePack) =>
          (hasPath(translationPath), languagePack) &&
          isString(path(translationPath, languagePack)),
      ),
      path(translationPath),
      (translation) => {
        if (translation === undefined) {
          throw new Error(`No translation found for ${label} in ${language}`)
        }
        return interpolate(translation, params)
      },
    )(languagePacks)

    return value || `{{${label}}}`
  }

export const translateWith = translate("nl")
