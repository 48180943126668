import { mergeRight, partition, isNil } from "ramda"

import { reduxStore as store } from "store"
import * as NotesApi from "entities/notes/notes.api"

export const NOTES_PUPIL_CREATE_START = "NPC_S"
export const NOTES_PUPIL_CREATE_END = "NPC_E"
export const NOTES_PUPIL_LOAD_START = "NPL_S"
export const NOTES_PUPIL_LOAD_END = "NPL_E"
export const NOTES_PUPIL_UPDATE_START = "NPUS_S"
export const NOTES_PUPIL_UPDATE_END = "NPUS_E"
export const NOTES_PUPIL_DELETE_START = "NPD_S"
export const NOTES_PUPIL_DELETE_END = "NPD_E"

export const NOTES_ARCHIVED_PUPIL_CREATE_START = "NAPC_S"
export const NOTES_ARCHIVED_PUPIL_CREATE_END = "NAPC_E"
export const NOTES_ARCHIVED_PUPIL_LOAD_START = "NAPL_S"
export const NOTES_ARCHIVED_PUPIL_LOAD_END = "NAPL_E"
export const NOTES_ARCHIVED_PUPIL_UPDATE_START = "NAPU_S"
export const NOTES_ARCHIVED_PUPIL_UPDATE_END = "NAPU_E"
export const NOTES_ARCHIVED_PUPIL_DELETE_START = "NAPD_S"
export const NOTES_ARCHIVED_PUPIL_DELETE_END = "NAPD_E"

/**
 * { function_description }
 *
 * @param  {Object}    arg1              Note data
 * @param  {string}    arg1.content      Text content
 * @param  {integer}   arg1.noteId       The note id
 * @param  {integer}   arg1.pupilId      Pupil id
 * @param  {?integer}  arg1.category     Category
 * @param  {integer}   arg1.schoolId     School id
 * @param  {integer}   arg1.yearclassId  YearClass id
 */
export const create = async ({
  pupilId,
  category,
  yearclass,
  content,
  position,
}) => {
  store.dispatch({
    type: NOTES_PUPIL_CREATE_START,
  })

  const note = await NotesApi.create({
    pupilId,
    category,
    content,
    position,
  })

  store.dispatch({
    type: NOTES_PUPIL_CREATE_END,
    payload: {
      createdItem: { ...note, yearclass },
    },
  })
}

/**
 * { function_description }
 *
 * @param  {integer}  pupilId  Pupil id
 *
 * @return {undefined}
 */
export const findAllPupilNotes = async (pupilId) => {
  store.dispatch({
    type: NOTES_PUPIL_LOAD_START,
  })
  store.dispatch({
    type: NOTES_ARCHIVED_PUPIL_LOAD_START,
  })

  const allNotes = await NotesApi.findAllByPupilId(pupilId)
  const [nonArchivedNotes, archivedNotes] = partition(
    (note) => isNil(note.dateArchived),
    allNotes,
  )
  store.dispatch({
    type: NOTES_ARCHIVED_PUPIL_LOAD_END,
    payload: {
      items: archivedNotes,
    },
  })

  store.dispatch({
    type: NOTES_PUPIL_LOAD_END,
    payload: {
      items: nonArchivedNotes,
    },
  })
}

/**
 * { function_description }
 *
 * @param  {integer}  id             Note id
 * @param  {Object}   arg2           The argument 2
 * @param  {integer}  arg2.category  Category id
 * @param  {string}   arg2.content   Note content
 *
 * @return {Object}
 */
export const update = async (id, { pupilId, category, content }) => {
  store.dispatch({
    type: NOTES_PUPIL_UPDATE_START,
    payload: {
      id,
    },
  })

  const note = await NotesApi.update(id, {
    pupilId,
    category,
    content,
  })

  store.dispatch({
    type: NOTES_PUPIL_UPDATE_END,
    payload: {
      updatedItem: note,
    },
  })

  return note
}

/**
 * { function_description }
 *
 * @param  {integer}  id             Note id
 * @param  {Object}   arg2           The argument 2
 * @param  {integer}  arg2.category  Category id
 * @param  {string}   arg2.content   Note content
 *
 * @return {Object}
 */
export const updateNote = async (
  id,
  { pupilId, categoryId, content, position },
  { shouldUpdateServer = true } = {},
) => {
  if (shouldUpdateServer) {
    store.dispatch({
      type: NOTES_PUPIL_UPDATE_START,
      payload: {
        id,
      },
    })
  }

  const note = shouldUpdateServer
    ? await NotesApi.update(id, {
        pupilId,
        category: categoryId,
        content,
        position,
      })
    : {
        id,
        categoryId,
        content,
        position,
      }

  store.dispatch({
    type: NOTES_PUPIL_UPDATE_END,
    payload: {
      updatedItem: note,
    },
  })

  return note
}

/**
 * { function_description }
 *
 * @param  {integer}  id        Note id
 * @param  {boolean}  archived  Is note archived
 *
 * @return {Object}
 */
export const archive = async (note) => {
  store.dispatch({
    type: NOTES_PUPIL_UPDATE_START,
    payload: {
      id: note.id,
    },
  })

  const updatedNote = await NotesApi.update(note.id, {
    archived: true,
  })

  // disable any spinners
  store.dispatch({
    type: NOTES_PUPIL_UPDATE_END,
    payload: {
      updatedItem: updatedNote,
    },
  })

  // remove from normal notes list
  store.dispatch({
    type: NOTES_PUPIL_DELETE_END,
    payload: {
      id: note.id,
    },
  })

  // add to archive notes list
  store.dispatch({
    type: NOTES_ARCHIVED_PUPIL_CREATE_END,
    payload: {
      createdItem: mergeRight(note, updatedNote),
    },
  })

  return note
}

/**
 * Update note with `archived` false
 *
 * - Remove from archived notes collection
 * - Add to normal notes collenction
 *
 * @param  {number}     id  Note id
 *
 * @return {undefined}
 */
export const unarchive = async (note) => {
  store.dispatch({
    type: NOTES_PUPIL_UPDATE_START,
    payload: {
      id: note.id,
    },
  })

  const updatedNote = await NotesApi.update(note.id, {
    archived: false,
  })

  store.dispatch({
    type: NOTES_PUPIL_UPDATE_END,
    payload: {
      updatedItem: { ...note, ...updatedNote },
    },
  })

  // remove from archived notes collection
  store.dispatch({
    type: NOTES_ARCHIVED_PUPIL_DELETE_END,
    payload: {
      id: note.id,
    },
  })

  // add to normal notes collection
  store.dispatch({
    type: NOTES_PUPIL_CREATE_END,
    payload: {
      createdItem: { ...note, ...updatedNote },
    },
  })

  return note
}

/**
 * { function_description }
 *
 * @param  {integer}  id  The identifier
 *
 * @return {undefined}
 */
export const remove = async (id) => {
  store.dispatch({
    type: NOTES_ARCHIVED_PUPIL_DELETE_START,
    payload: {
      id,
    },
  })

  await NotesApi.remove(id)

  store.dispatch({
    type: NOTES_ARCHIVED_PUPIL_DELETE_END,
    payload: {
      id,
    },
  })
}
