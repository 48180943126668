import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { LULoader } from "webclient-ui/components"

@withRouter
@connect((state) => ({
  isSchoolLoading: state.school.schoolLoading,
}))
class SchoolsWrapper extends React.Component {
  static propTypes = {
    schoolId: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    isSchoolLoading: PropTypes.bool,
  }

  // Defaults for non-required props
  static defaultProps = {
    isSchoolLoading: true,
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    const { children, isSchoolLoading } = this.props

    return <LULoader isLoading={isSchoolLoading}>{children}</LULoader>
  }
}

export { SchoolsWrapper }
