import { GET, POST, PATCH, DELETE } from "lib/core/api"
import { PupilSocialEmotionalNote } from "entities/pupils/pages/profile/social-emotional/social-emotional.reducer"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { serializeError } from "lib/core/redux-tools.utils"

export const getPupilSocialEmotionalNotes = createAsyncThunk<
  PupilSocialEmotionalNote[],
  number
>(
  "pupilSocialEmotionalNotes/getPupilSocialEmotionalNotes",
  async (pupilId: number) => {
    const result = await GET("/pupil_note", {
      query: {
        pupil_id: pupilId,
        category__name__in: "social_emotional",
      },
    })

    return result as PupilSocialEmotionalNote[]
  },
  {
    serializeError,
  },
)

export const createPupilSocialEmotionalNote = createAsyncThunk<
  PupilSocialEmotionalNote,
  { pupilId: number; content: string; position: number }
>(
  "pupilSocialEmotionalNotes/createPupilSocialEmotionalNote",
  async (data) => {
    const result = await POST("/pupil_note", {
      body: {
        ...data,
        category: "social_emotional",
      },
    })

    return result as PupilSocialEmotionalNote
  },
  {
    serializeError,
  },
)

export const updatePupilSocialEmotionalNote = createAsyncThunk<
  PupilSocialEmotionalNote,
  { id: number; data: Partial<PupilSocialEmotionalNote> }
>(
  "pupilSocialEmotionalNotes/updateSocialEmotionalNote",
  async (args) => {
    const result = await PATCH(`/pupil_note/${args.id}/`, {
      body: args.data,
    })

    return result as PupilSocialEmotionalNote
  },
  {
    serializeError,
  },
)

export const deletePupilSocialEmotionalNote = createAsyncThunk<
  { id: number },
  number
>(
  "pupilSocialEmotionalNotes/deleteSocialEmotionalNote",
  async (id: number) => {
    const result = await DELETE(`/pupil_note/${id}/`).then(() => {
      return {
        id,
      }
    })

    return result
  },
  {
    serializeError,
  },
)
