/**
 * Custom error for router-utils url building
 *
 * @class RouteNotFoundError
 */
export class RouteNotFoundError extends Error {
  constructor(name: string) {
    super(
      `RouteName Not Found: Cound not build route url using route name "${name}"`,
    )
    this.name = "RouteNotFoundError"
  }
}
