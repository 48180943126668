import { isNil, isEmpty } from "ramda"
import * as StorageAPI from "lib/core/storage.api"

export const FORMS_KEY = "forms"

export const hasDirtyForms = () => !isNil(StorageAPI.find(FORMS_KEY))

export const getSavedForms = () => {
  if (!hasDirtyForms()) {
    return {}
  }
  const dirtyForms = JSON.parse(StorageAPI.find(FORMS_KEY))
  return Object.entries(dirtyForms).reduce(
    (savedForms, [formName, formProperties]) => {
      if (formProperties.shouldSaveContent) {
        savedForms[formName] = formProperties
      }
      return savedForms
    },
    {},
  )
}

export const hasSavedForms = () => {
  if (hasDirtyForms()) {
    const savedForms = getSavedForms()
    return !isEmpty(savedForms)
  } else {
    return false
  }
}

export const setDirtyForm = (
  formName,
  formValue,
  shouldSaveContent = false,
) => {
  const dirtyForms = hasDirtyForms()
    ? JSON.parse(StorageAPI.find(FORMS_KEY))
    : {}
  StorageAPI.upsert(
    {
      key: FORMS_KEY,
      value: JSON.stringify({
        ...dirtyForms,
        [formName]: { formValue, shouldSaveContent },
      }),
    },
    { local: false },
  )
}

export const clearAllDirtyForms = () => {
  StorageAPI.remove(FORMS_KEY)
}

export const clearDirtyForm = (formName) => {
  if (hasDirtyForms()) {
    const forms = JSON.parse(StorageAPI.find(FORMS_KEY))
    delete forms[formName]
    if (isEmpty(forms)) {
      clearAllDirtyForms()
    } else {
      StorageAPI.upsert(
        {
          key: FORMS_KEY,
          value: JSON.stringify(forms),
        },
        { local: false },
      )
    }
  }
}
