import * as React from "react"
import cx from "classnames"
import { isEmpty } from "@leeruniek/functies"

import css from "./actions.module.css"
import MLUButton, { MLUButtonSize } from "components/ui/mlubutton/mlubutton"

export const LUActions = ({
  className = undefined,
  actions,
  size = MLUButtonSize.MEDIUM,
  align = "left",
  isBlock = true,
  hasSeparator = true,
  hasSeparatorLine = true,
}) => {
  const visibleActions = actions.filter((action) => action.isVisible !== false)

  return isEmpty(visibleActions) ? null : (
    <span
      className={cx(
        className,
        "actions",
        css[`actions--${align}`],
        css[`actions--${size}`],
        {
          [css["actions--block"] || ""]: isBlock,
        },
      )}>
      {visibleActions.map((action, idx) => {
        const icon = action.icon ? <i className={action.icon} /> : undefined
        const color = action.color || "primary"

        return (
          <React.Fragment key={`actions__action-${action.label}`}>
            <MLUButton
              className={css[`actions--${size}`]}
              {...action}
              color={color}
              size={size}
              icon={icon}
            />
            {hasSeparator && idx !== visibleActions.length - 1 ? (
              <span
                className={cx({
                  [css[`actions__separator--${size}`]]: hasSeparator,
                  [css.actions__separator__line]: hasSeparatorLine,
                })}
              />
            ) : null}
          </React.Fragment>
        )
      })}
    </span>
  )
}
