import * as React from "react"
import {
  Button,
  Palette,
  PaletteColor,
  PaletteOptions,
  Theme,
} from "@mui/material"

export enum MLUButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum MLUButtonType {
  DEFAULT = "contained",
  TEXT = "text",
}

interface MLUButtonProps {
  label: string
  className?: string
  style?: React.CSSProperties
  color?: keyof PaletteOptions
  dataTestId?: string
  disabled?: boolean
  disableElevation?: boolean
  endIcon?: React.ReactNode
  icon?: React.ReactNode
  size?: MLUButtonSize
  type?: MLUButtonType
  onClick: () => void
}

const MLUButton = ({
  label,
  className,
  style,
  color = "primary",
  dataTestId,
  disabled = false,
  disableElevation = true,
  endIcon,
  icon,
  size = MLUButtonSize.MEDIUM,
  type = MLUButtonType.DEFAULT,
  onClick,
}: MLUButtonProps) => {
  const buttonStyles = (theme: Theme) => {
    const palette =
      color && color in theme.palette
        ? (theme.palette[color as keyof Palette] as PaletteColor)
        : undefined

    return {
      "&.MuiButton-text": {
        color: palette && palette.main,
        "&:hover": {
          background: palette && palette.light,
          color: palette && palette.dark,
        },
        "&.Mui-disabled": {
          color: palette && palette.light,
          "&:hover": {
            background: "none",
          },
        },
      },
      "&.MuiButton-contained": {
        background: palette && palette.main,
        color: palette && palette.contrastText,
        "&:hover": {
          background: palette && palette.dark,
        },
        "&.Mui-disabled": {
          background: palette && palette.light,
          color: palette && palette.disabledContrastText,

          "&:hover": {
            background: palette && palette.light,
          },
        },
      },
    }
  }

  return (
    <Button
      className={className}
      style={style}
      data-testid={dataTestId}
      disabled={disabled}
      disableElevation={disableElevation}
      disableRipple={type === MLUButtonType.TEXT}
      size={size}
      endIcon={endIcon}
      startIcon={icon}
      variant={type}
      onClick={onClick}
      sx={buttonStyles}>
      {label}
    </Button>
  )
}

export default MLUButton
