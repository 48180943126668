import React from "react"
import TextField from "@mui/material/TextField"

import { setDirtyForm, clearDirtyForm } from "lib/core/forms.utils"
import { usePrevious } from "lib/core/react.utils"

// Apply default props and styles to on material-ui TextField component.
const MLUTextField = ({
  shouldAbortLeave = false,
  formName = "",
  isDirty = false,
  shouldSaveContent = false,
  variant = "standard",
  ...props
}) => {
  const textFieldStyles = (theme) => ({
    backgroundColor: "#f3f3f4",
    "& .MuiInputBase-multiline": {
      padding: "5px",
    },

    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        lineHeight: 1.5,
        padding: "5px",

        "&::placeholder": {
          fontStyle: "italic",
          color: theme.palette.text.primary,
          opacity: 1,
        },
      },
    },
  })

  const prevIsDirty = usePrevious(isDirty)

  if (shouldAbortLeave) {
    const beforeunload = function (e) {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = true
      }
    }

    React.useEffect(() => {
      return setDirtyForm(formName, props.value, shouldSaveContent)
    }, [props.value])

    React.useEffect(() => {
      if (prevIsDirty !== isDirty) {
        if (isDirty === true) {
          window.addEventListener("beforeunload", beforeunload)
        } else {
          clearDirtyForm(formName)
        }
        return () => {
          if (isDirty) {
            clearDirtyForm(formName)
          }
          window.removeEventListener("beforeunload", beforeunload)
        }
      }
    }, [isDirty])
  }
  return (
    <TextField
      {...props}
      variant={variant}
      InputProps={{
        disableUnderline: true,
        ...(props.InputProps || {}),
      }}
      sx={textFieldStyles}
    />
  )
}

export default MLUTextField
