import contentType from "content-type"
import { stringify } from "qs"
import { map, range, includes, pipe } from "ramda"

import { getToken, isDemoUser, isImpersonatedUser } from "lib/core/session.api"
import { trim, isURL } from "lib/core/utils"
import RequestError from "lib/core/errors/request.error"

/**
 * Base API URL
 */
export const getAPIUrl = ({ isMock = false } = {}) =>
  isMock ? `${WEBP_MOCK_API_URL}/api/1.0` : `${WEBP_API_URL}/api/1.0`

/**
 * List of http methods used for updating resources
 */
const updateMethods = ["DELETE", "PATCH", "POST", "PUT"]

/**
 * If using demo user or impersonating don't make any
 *
 * @param  {string}   method    The method
 * @param  {string}   endpoint  The endpoint
 *
 * @return {boolean}  True if dry run, False otherwise.
 */
const _isDryRun = (method, endpoint) => {
  const allowMethod = method === "GET"
  const allowURL = endpoint.includes("login") || endpoint.includes("printout")

  return allowURL || allowMethod ? false : isDemoUser() || isImpersonatedUser()
}

/**
 * Generic `fetch` wrapper with:
 *      - default headers
 *      - JWT token in `Authorization` header
 *      - base API url
 *
 * @param  {string}                    method              HTTP method
 * @param  {string}                    endpoint            API endpoint
 * @param  {Object}                    arg3                Req
 * @param  {Object<string,string|[]>}  arg3.query          Req query params
 * @param  {Object<string,string>}     arg3.headers        Req headers
 * @param  {Object<string,*>}          arg3.body           Req body
 * @param  {Object}                    arg4                Req options
 * @param  {boolean}                   arg4.trailingSlash  Weather to add a
 *                                                         trailing
 *
 * @return {Promise}                   Promise that resolves with the response
 *                                     object if code is 20*. Reject all other
 *                                     response codes.
 */
const request = async (
  method,
  endpoint,
  { query = null, headers = {}, body = {} } = {},
  { trailingSlash = true, isMock = false } = {},
  isForm = false,
) => {
  if (isForm && !includes(method)(updateMethods)) {
    throw new Error(
      "isForm cannot be used in combination with non-updating request",
    )
  }

  /*
   * TODO: this should be at action level, to feed dummy data to the reducers
   */
  if (_isDryRun(method, endpoint)) {
    return body
  }

  // Just to be safe, trim out "/", put it back in the url string template
  const URN = trim(endpoint, "/")

  const API_URL = getAPIUrl({ isMock })

  /*
   * Check if requesting an outside service or internal API
   * Deciding if it's an URN or a URL ... :))
   */
  const isOutside = isURL(endpoint) && !URN.startsWith(API_URL)

  const fullURL = pipe(
    // Just to be safe, trim out "/", put it back in the url string template
    (url) => trim(url, "/"),

    // Dont prefix API_URL if url is absolute
    (url) => (isURL(url) ? url : `${API_URL}/${url}`),

    // Add trailing slash back
    (url) => (trailingSlash ? `${url}/` : url),

    // Query string parsing, `request` lib also uses `qs`
    (url) =>
      query === null
        ? url
        : `${url}?${stringify(query, {
            allowDots: true,
            encode: false,
            arrayFormat: "brackets",
            strictNullHandling: true,
          })}`,
  )(endpoint)

  /*
   * If getToken is undefined the fetch lib will still put the Auth header with
   * value `undefined`. Also dont put JWT token for outside requrests.
   */
  const token = getToken()
  const authHeaders =
    token && !isOutside
      ? {
          Authorization: `JWT ${token}`,
        }
      : {}

  /*
   * Req body for PATCH, POST and PUT requests. Ignore `body` key to avoid
   * "HEAD or GET Request cannot have a body"
   */
  let _body = {}
  if (isForm) {
    _body = { body: body }
  } else if (includes(method)(updateMethods)) {
    _body = { body: JSON.stringify(body) }
  }

  let _headers = {
    ...headers,
    ...authHeaders,
  }
  if (!isForm) {
    _headers = {
      Accept: "application/json, text/html",
      "Content-Type": "application/json",
      ..._headers,
    }
  }
  const response = await fetch(fullURL, {
    method,
    headers: _headers,
    ..._body,
  })

  const isResponseJSON =
    response.headers.has("Content-Type") &&
    contentType.parse(response.headers.get("Content-Type")).type ===
      "application/json"
  const responseBody = isResponseJSON ? response.json() : response.text()

  /*
   * The Promise returned from fetch() won't reject on HTTP error status
   * even if the response is an HTTP 404 or 500. Instead, it will resolve
   * normally, and it will only reject on network failure or if anything
   * prevented the request from completing.
   */
  if (response.ok) {
    return responseBody
  }

  const result = await responseBody
  throw new RequestError(response, result)
}

/**
 * POST
 *
 * @param  {string}   url      API endpoint
 * @param  {Object}   data     Req body or Req body & headers
 * @param  {Object}   options  Req options
 *
 * @return {Promise}  Promise that resolves with the response object if code is
 *                    20*. Reject all other response codes.
 */
export const POST = (url, data, options = {}, isForm = false) => {
  const { headers, query } = data
  const body = data.body || data

  return request(
    "POST",
    url,
    {
      body,
      headers,
      query,
    },
    options,
    isForm,
  )
}

export const PUT = (url, data, options = {}, isForm = false) => {
  const { headers, query } = data
  const body = data.body || data

  return request(
    "PUT",
    url,
    {
      body,
      headers,
      query,
    },
    options,
    isForm,
  )
}

/**
 * PATCH
 *
 * @param  {string}   url      API endpoint
 * @param  {Object}   data     Req body or Req body & headers
 * @param  {Object}   options  Req options
 *
 * @return {Promise}  Promise that resolves with the response object if code is
 *                    20*. Reject all other response codes.
 */
export const PATCH = (url, data, options = {}) => {
  const { headers } = data
  const body = data.body || data

  return request(
    "PATCH",
    url,
    {
      body,
      headers,
    },
    options,
  )
}

/**
 * GET
 *
 * @param  {string}                         url           API endpoint
 * @param  {Object<string, Object>}         arg2          Req
 * @param  {Object<string, number|string>}  arg2.query    Req query params
 * @param  {Object<string, string>}         arg2.headers  Req headers
 * @param  {Object}                         options       Req options
 *
 * @return {Promise}                        Promise that resolves with the
 *                                          response object if code is 20*.
 *                                          Reject all other response codes.
 */
export const GET = (url, { query, headers } = {}, options = {}) =>
  request(
    "GET",
    url,
    {
      query,
      headers,
    },
    options,
  )

/**
 * GET_ALL
 *
 * Get all results from a paginated API resource. Only works for the Node API.
 *
 * @param   {string}                        url           API endpoint
 * @param   {Object<string, Object>}        arg2          Req
 * @param   {Object<string, number|string>} arg2.query    Req query params
 * @param   {Object<string, string>}        arg2.headers  Req headers
 * @param   {Object}                        options       Req options
 *
 * @return  {Promise}                       Promise that resolves with the
 *                                          response object if code is 20*.
 *                                          Reject all other response codes.
 */
export const GET_ALL = async (url, { query, headers }, options) => {
  const firstPage = await GET(url, { query, headers }, options)

  const pageSize = query.limit ?? firstPage.rows.length
  const pageCount = Math.ceil(firstPage.count / pageSize)

  if (pageCount <= 1) {
    return Promise.resolve(firstPage)
  }

  const pageQueries = map((pageIndex) =>
    GET(url, { query: { ...query, offset: pageSize * pageIndex } }, options),
  )(range(1, pageCount + 1))

  return Promise.all(pageQueries).then((pages) => ({
    count: firstPage.count,
    rows: pages.reduce((acc, val) => acc.concat(val.rows), firstPage.rows),
  }))
}

/**
 * DELETE
 *
 * @param  {string}                  url           API endpoint
 * @param  {Object<string, Object>}  arg2          Req
 * @param  {Object<string, string>}  arg2.headers  Req headers
 * @param  {Object}                  options       Req options
 *
 * @return {Promise}                 Promise that resolves with the response
 *                                   object if code is 20*. Reject all other
 *                                   response codes.
 */
export const DELETE = (url, data = {}, options = {}) => {
  const { headers } = data
  const body = data.body || data

  return request("DELETE", url, { body, headers }, options)
}
