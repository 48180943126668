import { createReducer, SerializedError } from "@reduxjs/toolkit"
import { GroupPlanNote } from "entities/group-plans/group-plans.interfaces"
import {
  NoteCategory,
  NoteCategoryAutocomplete,
} from "entities/note-categories/note-categories.interfaces"
import {
  getNoteCategories,
  getNotes,
  createNoteCategory,
  updateNoteCategory,
  deleteNoteCategory,
  updateNoteCategoryInState,
  createNote,
  updateNote,
  deleteNote,
  updateNoteInState,
  getCategoriesAutocomplete,
  getSubcategoriesAutocomplete,
} from "entities/admin__templates.page/selected.section/selected.actions"

interface State {
  noteCategories: NoteCategory[]
  categoriesUpdatingIds: number[]
  categoriesDeletingIds: number[]
  isCategoriesCreating: boolean
  isCategoriesLoading: boolean
  notes: GroupPlanNote[]
  notesUpdatingIds: number[]
  notesDeletingIds: number[]
  isNotesCreating: boolean
  isNotesLoading: boolean
  categoriesAutocomplete: NoteCategoryAutocomplete[]
  subcategoriesAutocomplete: NoteCategoryAutocomplete[]
  error: SerializedError | null
}

const initialState: State = {
  noteCategories: [],
  categoriesUpdatingIds: [],
  categoriesDeletingIds: [],
  isCategoriesCreating: false,
  isCategoriesLoading: false,
  notes: [],
  notesUpdatingIds: [],
  notesDeletingIds: [],
  isNotesCreating: false,
  isNotesLoading: false,
  categoriesAutocomplete: [],
  subcategoriesAutocomplete: [],
  error: null,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(getNoteCategories.pending, (state) => {
    state.isCategoriesLoading = true
  })
  builder.addCase(getNoteCategories.fulfilled, (state, action) => {
    state.isCategoriesLoading = false
    state.noteCategories = action.payload
  })
  builder.addCase(getNoteCategories.rejected, (state, action) => {
    state.isCategoriesLoading = false
    state.error = action.error
  })
  builder.addCase(getNotes.pending, (state) => {
    state.isNotesLoading = true
  })
  builder.addCase(getNotes.fulfilled, (state, action) => {
    state.isNotesLoading = false
    state.notes = action.payload
  })
  builder.addCase(getNotes.rejected, (state, action) => {
    state.isNotesLoading = false
    state.error = action.error
  })
  builder.addCase(createNoteCategory.pending, (state) => {
    state.isCategoriesCreating = true
  })
  builder.addCase(createNoteCategory.fulfilled, (state, action) => {
    state.isCategoriesCreating = false
    state.noteCategories.push(action.payload)
  })
  builder.addCase(createNoteCategory.rejected, (state, action) => {
    state.isCategoriesCreating = false
    state.error = action.error
  })
  builder.addCase(updateNoteCategory.pending, (state, action) => {
    state.categoriesUpdatingIds.push(action.meta.arg.id)
  })
  builder.addCase(updateNoteCategory.fulfilled, (state, action) => {
    state.categoriesUpdatingIds = state.categoriesUpdatingIds.filter(
      (id) => id !== action.payload.id,
    )
    const updatedIndex = state.noteCategories.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (updatedIndex !== -1) {
      state.noteCategories[updatedIndex] = action.payload
    }
  })
  builder.addCase(updateNoteCategory.rejected, (state, action) => {
    state.categoriesUpdatingIds = state.categoriesUpdatingIds.filter(
      (id) => id !== action.meta.arg.id,
    )
    state.error = action.error
  })
  builder.addCase(deleteNoteCategory.pending, (state, action) => {
    state.categoriesDeletingIds.push(action.meta.arg)
  })
  builder.addCase(deleteNoteCategory.fulfilled, (state, action) => {
    state.categoriesDeletingIds = state.categoriesDeletingIds.filter(
      (id) => id !== action.payload.id,
    )
    state.noteCategories = state.noteCategories.filter(
      (category) => category.id !== action.payload.id,
    )
  })
  builder.addCase(deleteNoteCategory.rejected, (state, action) => {
    state.categoriesDeletingIds = state.categoriesDeletingIds.filter(
      (id) => id !== action.meta.arg,
    )
    state.error = action.error
  })
  builder.addCase(updateNoteCategoryInState, (state, action) => {
    const categoryIndex = state.noteCategories.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (categoryIndex !== -1) {
      state.noteCategories[categoryIndex] = {
        ...state.noteCategories[categoryIndex],
        ...action.payload.data,
      }
    }
  })
  builder.addCase(createNote.pending, (state) => {
    state.isNotesCreating = true
  })
  builder.addCase(createNote.fulfilled, (state, action) => {
    state.isNotesCreating = false
    state.notes.push(action.payload)
  })
  builder.addCase(createNote.rejected, (state, action) => {
    state.isNotesCreating = false
    state.error = action.error
  })
  builder.addCase(updateNote.pending, (state, action) => {
    state.notesUpdatingIds.push(action.meta.arg.id)
  })
  builder.addCase(updateNote.fulfilled, (state, action) => {
    state.notesUpdatingIds = state.notesUpdatingIds.filter(
      (id) => id !== action.payload.id,
    )
    const updatedIndex = state.notes.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (updatedIndex !== -1) {
      state.notes[updatedIndex] = action.payload
    }
  })
  builder.addCase(updateNote.rejected, (state, action) => {
    state.notesUpdatingIds = state.notesUpdatingIds.filter(
      (id) => id !== action.meta.arg.id,
    )
    state.error = action.error
  })
  builder.addCase(deleteNote.pending, (state, action) => {
    state.notesDeletingIds.push(action.meta.arg)
  })
  builder.addCase(deleteNote.fulfilled, (state, action) => {
    state.notesDeletingIds = state.notesDeletingIds.filter(
      (id) => id !== action.payload.id,
    )
    state.notes = state.notes.filter(
      (category) => category.id !== action.payload.id,
    )
  })
  builder.addCase(deleteNote.rejected, (state, action) => {
    state.notesDeletingIds = state.notesDeletingIds.filter(
      (id) => id !== action.meta.arg,
    )
    state.error = action.error
  })
  builder.addCase(updateNoteInState, (state, action) => {
    const noteIndex = state.notes.findIndex(
      (category) => category.id === action.payload.id,
    )

    if (noteIndex !== -1) {
      state.notes[noteIndex] = {
        ...state.notes[noteIndex],
        ...action.payload.data,
      }
    }
  })
  builder.addCase(getCategoriesAutocomplete.pending, (state) => state)
  builder.addCase(getCategoriesAutocomplete.fulfilled, (state, action) => {
    state.categoriesAutocomplete = action.payload
  })
  builder.addCase(getCategoriesAutocomplete.rejected, (state, action) => {
    state.error = action.error
  })
  builder.addCase(getSubcategoriesAutocomplete.pending, (state) => state)
  builder.addCase(getSubcategoriesAutocomplete.fulfilled, (state, action) => {
    state.subcategoriesAutocomplete = action.payload
  })
  builder.addCase(getSubcategoriesAutocomplete.rejected, (state, action) => {
    state.error = action.error
  })
})
