import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["invite__register"]: {
    nl: "Account aanmaken",
    en: "Create account",
  },
  ["invite__first-name-placeholder"]: {
    nl: "Voornaam",
    en: "First name",
  },
  ["invite__last-name-placeholder"]: {
    nl: "Achternaam",
    en: "Last name",
  },
  ["invite__password-placeholder"]: {
    nl: "Wachtwoord",
    en: "Password",
  },
  ["invite__password-confirm-placeholder"]: {
    nl: "Bevestig wachtwoord",
    en: "Confirm password",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
