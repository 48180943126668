import { reduxStore as store } from "store"
import { GET, PATCH, DELETE, POST } from "lib/core/api"
import * as NoteCategoriesAPI from "entities/note-categories/note-categories.api"

export const PUPILS_NOTE_CATEGORIES_CREATE_START = "PNCC_S"
export const PUPILS_NOTE_CATEGORIES_CREATE_END = "PNCC_E"
export const PUPILS_NOTE_CATEGORIES_LOAD_START = "PNCL_S"
export const PUPILS_NOTE_CATEGORIES_LOAD_END = "PNCL_E"
export const PUPILS_NOTE_CATEGORIES_UPDATE_START = "PNCU_S"
export const PUPILS_NOTE_CATEGORIES_UPDATE_END = "PNCU_E"
export const PUPILS_NOTE_CATEGORIES_UPDATE_NAME_END = "PNCUN_E"
export const PUPILS_NOTE_CATEGORIES_DELETE_START = "PNCD_S"
export const PUPILS_NOTE_CATEGORIES_DELETE_END = "PNCD_E"

/**
 * CREATE
 *
 * @param  {Object}     arg1                 The argument 1
 * @param  {integer}    arg1.pupilId         The pupil identifier
 * @param  {integer}    arg1.noteCategoryId  The note category identifier
 * @param  {float}      arg1.position        The position
 *
 * @return {undefined}
 */
export const create = async ({ pupilId, noteCategoryId, position }) => {
  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_CREATE_START,
  })

  const pupilCategory = await POST("/pupil_note_categories/", {
    body: {
      pupil_id: pupilId,
      note_category_id: noteCategoryId,
      position,
    },
  })

  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_CREATE_END,
    payload: {
      createdItem: pupilCategory,
    },
  })

  return pupilCategory
}

/**
 * READ all
 *
 * @param  {integer}  pupilId  The pupil identifier
 *
 * @return {undefined}
 */
export const findAll = async (pupilId, { setIsLoading = true } = {}) => {
  if (setIsLoading) {
    store.dispatch({
      type: PUPILS_NOTE_CATEGORIES_LOAD_START,
    })
  }

  const categories = await GET("/pupil_note_categories/", {
    query: {
      user: false,
      pupil_id: pupilId,
      "include[]":
        "id,pupil_id,note_category_id,position,note_category,date_created,date_changed",
    },
  })

  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_LOAD_END,
    payload: {
      items: categories,
    },
  })
}

/**
 * UPDATE
 *
 * @param  {integer}    id                   The identifier
 * @param  {Object}     arg2                 The argument 2
 * @param  {integer}    arg2.pupilId         The pupil identifier
 * @param  {integer}    arg2.noteCategoryId  The note category identifier
 * @param  {float}      arg2.position        The position
 *
 * @return {undefined}
 */
export const updatePosition = async (
  id,
  { pupilId, noteCategoryId, position },
) => {
  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_UPDATE_START,
    payload: {
      id,
      pupilId,
    },
  })

  const pupilCategory = await PATCH(`/pupil_note_categories/${id}`, {
    body: {
      pupilId,
      noteCategoryId,
      position,
    },
  })

  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_UPDATE_END,
    payload: {
      pupilId,
      updatedItem: pupilCategory,
    },
  })
}

export const update = async (id, { name }) => {
  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_UPDATE_START,
    payload: {
      id,
    },
  })

  const updatedCategory = await NoteCategoriesAPI.update(id, { name })

  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_UPDATE_NAME_END,
    payload: {
      updatedCategoryId: id,
      updatedCategory,
    },
  })
}

/**
 * UPDATE
 *
 * @param  {integer}    id                   The identifier
 * @param  {Object}     arg2                 The argument 2
 * @param  {integer}    arg2.pupilId         The pupil identifier
 * @param  {integer}    arg2.noteCategoryId  The note category identifier
 * @param  {float}      arg2.position        The position
 *
 * @return {undefined}
 */
export const remove = async (id) => {
  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_DELETE_START,
    payload: {
      id,
    },
  })

  await DELETE(`/pupil_note_categories/${id}`)

  store.dispatch({
    type: PUPILS_NOTE_CATEGORIES_DELETE_END,
    payload: {
      id,
    },
  })
}
