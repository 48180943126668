import * as React from "react"
import { isNil } from "ramda"

import { history } from "store"
import { UserLayoutFooter } from "entities/user.layout/user__footer"
import { t } from "components/error/index.i18n"
import css from "components/error/index.module.scss"
import MLUButton from "components/ui/mlubutton/mlubutton"

const ClientErrorView = ({ isAccessError, isNotFound, onErrorsClean }) => {
  const [showFeedbackForm, setShowFeedbackForm] = React.useState(false)

  React.useEffect(() => {
    return () => {
      if (!isNil(onErrorsClean)) {
        onErrorsClean()
      }
    }
  })
  const handleChangeLocation = () => {
    history.push("/app")
    window.location.reload()
  }

  const handleFeedbackClose = () => {
    setShowFeedbackForm(false)
  }

  const render403Content = () => (
    <div className={css.text_body} data-testid={"client_error_view--403"}>
      <h1 className={css.error_title}>
        {t("unexpected__error__message__oeps")}
      </h1>
      <p>{t("access_error_message_part_1")} </p>
      <p>{t("access_error_message_part_2")} </p>
    </div>
  )
  const render404Content = () => (
    <div className={css.text_body} data-testid={"client_error_view--404"}>
      <h1 className={css.error_title}>
        {t("unexpected__error__message__oeps")}
      </h1>
      <p>{t("404_error_message_part_1")} </p>
      <p>{t("404_error_message_part_2")} </p>
    </div>
  )

  return (
    <div className={css.text_wrapper} data-testid={"client_error_view"}>
      {isNotFound
        ? render404Content()
        : isAccessError
          ? render403Content()
          : null}
      <div className={css.buttons_wrapper}>
        <MLUButton
          onClick={handleChangeLocation}
          className={css.go_to_homepage_button}
          label={t("error__go__homepage")}
        />
      </div>
      <div className={css.error_logo} />
      <UserLayoutFooter
        key="user-layout__footer"
        showFeedbackForm={showFeedbackForm}
        handleFeedbackCloseCallback={handleFeedbackClose}
      />
    </div>
  )
}

export default ClientErrorView
