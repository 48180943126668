import { createReducer, SerializedError } from "@reduxjs/toolkit"
import {
  getPupilInstructionGroups,
  createPupilInstructionGroup,
  getPupilInstructionGroupsHistory,
} from "entities/pupils/pages/profile/instruction-groups/instruction-groups.actions"

export interface PupilInstructionGroup {
  id: number
  approach: string
  pupilId: number
  subjectId: number
  yearclassId: number
  dateChanged: string
  userChangedId: number
  userChangedName: string
  schoolYear: string
}

export interface PupilInstructionGroupHistoryItem
  extends PupilInstructionGroup {
  pupilApproach: PupilInstructionGroup
}

interface State {
  groups: PupilInstructionGroup[]
  history: PupilInstructionGroupHistoryItem[]
  isCreating: boolean
  error: SerializedError | null
}

const initialState: State = {
  groups: [],
  history: [],
  isCreating: false,
  error: null,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(getPupilInstructionGroups.pending, (state) => state)
  builder.addCase(getPupilInstructionGroups.fulfilled, (state, action) => {
    state.groups = action.payload
  })
  builder.addCase(getPupilInstructionGroups.rejected, (state, action) => {
    state.error = action.error
  })
  builder.addCase(createPupilInstructionGroup.pending, (state) => {
    state.isCreating = true
  })
  builder.addCase(createPupilInstructionGroup.fulfilled, (state, action) => {
    state.isCreating = false
    state.groups.push(action.payload)
  })
  builder.addCase(createPupilInstructionGroup.rejected, (state, action) => {
    state.isCreating = false
    state.error = action.error
  })
  builder.addCase(getPupilInstructionGroupsHistory.pending, (state) => state)
  builder.addCase(
    getPupilInstructionGroupsHistory.fulfilled,
    (state, action) => {
      state.history = action.payload
    },
  )
  builder.addCase(
    getPupilInstructionGroupsHistory.rejected,
    (state, action) => {
      state.error = action.error
    },
  )
})
