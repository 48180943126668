import { createBrowserHistory } from "history"
import { combineReducers, compose } from "redux"
import { createReduxHistoryContext } from "redux-first-history"
import { isPlain } from "@reduxjs/toolkit"

import { doorbellReducers } from "entities/doorbell/doorbell.reducers"
import { pupilsProfilePageReducers } from "entities/pupils/pages/profile/pupils-profile.reducers"

/**
 * Do it like this, it scales better. Every page has it's own
 * reducers object. Destructure when loading the store.
 */

// Groups
import { groupsWorksheetPageReducers } from "entities/groups__worksheet.page/worksheet.reducers"

// Admin
import adminTemplatesPageOverviewReducer from "entities/admin__templates.page/overview.section/overview.reducer"
import adminTemplatesPageSelectedReducer from "entities/admin__templates.page/selected.section/selected.reducer"
import adminUploadPageReducer from "entities/admin__upload.page/admin__upload.reducers"
import adminNotesPageReducer from "entities/admin__notes.page/admin__notes.reducers"
import adminPermissionsReducer from "entities/admin__permissions.page/admin__permissions.reducers"

import schoolsReduxReducer from "entities/schools/schools.reducers"
import groupsReduxReducer from "entities/groups/groups.reducer"
import sessionReducer from "entities/sessions/sessions.reducers"
import yeargradesReducer from "entities/yeargrades/yeargrades.reducers"
import pupilsReducer from "entities/pupils/pupils.reducers"
import seQuestionsReducer from "entities/social-emotional/social-emotional.reducers"
import subjectsReducer from "entities/subjects/subjects.reducer"
import methodsReducer from "entities/methods/methods.reducer"
import groupGoalsReducer from "entities/groups__goals.page/group_goals.reducers"
import groupNotesPageReducer from "entities/group__notes.page/group__notes.reducers"

// Authentication
import multiFactorAuthAppReducer from "entities/multifactor-auth-app/multifactor-auth-app.reducer"

import { showPromptModal } from "components/prompt-modal/prompt-modal"
import { configureStore } from "@reduxjs/toolkit"
import { t } from "lib/core/i18n/root.i18"

const composeEnhancers =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  WEBP_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory({
      getUserConfirmation(message, callback) {
        return showPromptModal(
          t("leave-unsaved-changes-notification-title"),
          t(message),
          callback,
        )
      },
    }),
  })

export const createStore = (preloadedState = {}) =>
  configureStore({
    reducer: combineReducers({
      ...groupsWorksheetPageReducers,

      router: routerReducer,
      ...doorbellReducers,
      ...pupilsProfilePageReducers,
      school: schoolsReduxReducer,
      session: sessionReducer,
      adminUpload: adminUploadPageReducer,
      adminNotes: adminNotesPageReducer,
      yearclass: groupsReduxReducer,
      yeargrades: yeargradesReducer,
      pupils: pupilsReducer,
      subjects: subjectsReducer,
      methods: methodsReducer,
      permissions: adminPermissionsReducer,
      seQuestions: seQuestionsReducer,
      groupGoals: groupGoalsReducer,
      groupNotes: groupNotesPageReducer,
      adminTemplates: adminTemplatesPageOverviewReducer,
      adminTemplatesSelected: adminTemplatesPageSelectedReducer,
      multiFactorAuthApp: multiFactorAuthAppReducer,
    }),
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      composeEnhancers(
        getDefaultMiddleware({
          // TODO: This is a slightly more permissive serializability check than the
          // default (`isPlain(value)`) which also allows `Error`s to be persisted in
          // Redux state. This can be removed once no actions store errors in Redux
          // state, which firstly requires migrating all usage of `createAsyncAction` to
          // `createAsyncThunk`, and secondly requires all `createAsyncThunk` calls to
          // pass an appropriate `serializeError` parameter
          // (see docs: https://redux-toolkit.js.org/api/createAsyncThunk#options)
          //
          // NOTE: The `serializeError` function implemented in src/lib/core/redux-tools.utils.ts
          // does _not_ qualify here since it returns `Error` instances.
          serializableCheck: {
            isSerializable: (value) => isPlain(value) || value instanceof Error,
          },
        }).concat(routerMiddleware),
      ),
  })

/**
 * App redux store
 *
 * @type {Function}
 */
export const reduxStore = createStore()

/**
 * Router browser history
 *
 * @type {Function}
 */
export const history = createReduxHistory(reduxStore)
