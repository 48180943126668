import React from "react"
import { createRoot } from "react-dom/client"
import { ThemeProvider } from "@mui/material"
import LUTheme from "components/ui/themes/lutheme"
import MLUButton from "components/ui/mlubutton/mlubutton"
import MLUDialog, {
  ActionsAlignment,
  DialogSize,
} from "components/ui/mludialog/mludialog"
import { t } from "lib/core/i18n/root.i18"

export const showPromptModal = (title, message, callback) => {
  const container = document.createElement("div")
  container.setAttribute("custom-confirmation-navigation", "")
  document.body.appendChild(container)
  const reactRoot = createRoot(container)

  const closeModal = (callbackState) => {
    reactRoot.unmount()
    callback(callbackState)
  }

  reactRoot.render(
    <ThemeProvider theme={LUTheme}>
      <MLUDialog
        open
        title={title}
        size={DialogSize.AUTO}
        color="danger"
        actionsAlignment={ActionsAlignment.CENTER}
        actions={
          <MLUButton
            color="lightGray"
            label={t("close")}
            onClick={() => closeModal(false)}
          />
        }>
        <div style={{ textAlign: "center" }}>{message}</div>
      </MLUDialog>
    </ThemeProvider>,
  )
}
