import { PaletteColorOptions, createTheme } from "@mui/material"

declare module "@mui/material/styles" {
  interface PaletteColor {
    disabledContrastText: string
  }
  interface SimplePaletteColorOptions {
    disabledContrastText?: string
  }
  interface PaletteOptions {
    gray?: PaletteColorOptions
    lightGray?: PaletteColorOptions
    danger?: PaletteColorOptions
    purple?: PaletteColorOptions
    red?: PaletteColorOptions
    teal?: PaletteColorOptions
    yellow?: PaletteColorOptions
  }
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    titleFontSize: number
  }

  interface TypographyOptions {
    titleFontSize?: number
  }
}

const HEADING_FONT_FAMILY = [
  "Comfortaa",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",")

const LUTheme = createTheme({
  palette: {
    common: { black: "#676a6c", white: "#fff" },
    background: { paper: "#fff", default: "#f3f3f4" },
    primary: {
      light: "rgba(61, 204, 180, 0.1)",
      main: "rgba(61, 204, 180, 1)",
      dark: "rgba(49, 163, 144, 1)",
      contrastText: "#fff",
      disabledContrastText: "rgba(61, 204, 180, 0.8)",
    },
    gray: {
      light: "#e3e3e4",
      main: "#979797",
      dark: "#696969",
      contrastText: "#fff",
      disabledContrastText: "#aaa",
    },
    lightGray: {
      light: "#f9f9f9",
      main: "#f3f3f4",
      dark: "#d5d5d5",
      contrastText: "#999",
      disabledContrastText: "#bbb",
    },
    purple: {
      light: "rgba(118,109,145, 0.1)",
      main: "rgba(118,109,145, 1)",
      dark: "#5e5774",
      contrastText: "#fff",
      disabledContrastText: "rgba(118,109,145, 0.8)",
    },
    yellow: {
      light: "rgba(244,150,6, 0.1)",
      main: "rgba(244,150,6, 1)",
      dark: "#c4851c",
      contrastText: "#fff",
      disabledContrastText: "rgba(244,150,6, 0.8)",
    },
    danger: {
      light: "#f4d7d7",
      main: "#cc3d3d",
      dark: "#992e2e",
      contrastText: "#fff",
      disabledContrastText: "#d45e5e",
    },
    text: {
      primary: "#676a6c",
      secondary: "#2f4051",
    },
    red: {
      main: "#fe6b6b",
      light: "#f7cbcb",
      dark: "#bb575a",
      contrastText: "#fff",
      disabledContrastText: "#eee",
    },
    teal: {
      main: "#3dccb4",
      light: "#bce9e1",
      dark: "#349b8d",
    },
  },
  spacing: 30,
  typography: {
    htmlFontSize: 13,
    fontFamily: ["Montserrat", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 13,
    titleFontSize: 18,
    h1: { fontFamily: HEADING_FONT_FAMILY },
    h2: { fontFamily: HEADING_FONT_FAMILY },
    h3: { fontFamily: HEADING_FONT_FAMILY },
    h4: { fontFamily: HEADING_FONT_FAMILY },
    h5: { fontFamily: HEADING_FONT_FAMILY },
    h6: { fontFamily: HEADING_FONT_FAMILY },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "3px",
          fontSize: theme.typography.fontSize,
          height: "32px",
          letterSpacing: 0,
          lineHeight: "32px",
          minWidth: "130px",
          padding: "0 13px",
          textTransform: "none",

          "&.Mui-disabled": {
            cursor: "not-allowed",
            pointerEvents: "unset",
          },
        }),
        contained: {
          fontWeight: 700,
        },
        sizeSmall: {
          height: "25px",
          minWidth: "100px",
          fontSize: "12px",
          lineHeight: "25px",
          fontWeight: 500,
        },
        endIcon: {
          "& > *:nth-of-type(1)": {
            fontSize: "inherit",
          },
        },
        startIcon: {
          "& > *:nth-of-type(1)": {
            fontSize: "inherit",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          maxHeight: "none",
          maxWidth: "none",
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.text.secondary,
          fontSize: theme.typography.titleFontSize,
          lineHeight: "100%",
          margin: theme.spacing(1.5),
          marginBottom: theme.spacing(0.5),
          padding: 0,
          paddingBottom: theme.spacing(0.5),
          textAlign: "left",
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontSize: theme.typography.fontSize,
          margin: theme.spacing(1.5),
          marginTop: 0,
          padding: 0,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          margin: theme.spacing(1.5),
          marginTop: 0,
          padding: 0,
          paddingTop: theme.spacing(0.5),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 2px 3px 3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: ({ theme }) => ({
          fontSize: "1.5rem",
          fontWeight: "700",
          color: theme.palette.text.secondary,
          "& > a": {
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          },
        }),
        action: {
          marginTop: "inherit",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: "1.2rem",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        input: {
          // This is required due to a conflict with bootstrap
          margin: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "14px",
          fontWeight: 700,
          minHeight: "1em",
          textTransform: "none",

          ".MuiTab-iconWrapper": { marginRight: theme.spacing(1 / 3) },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "1em",
        },
      },
    },
  },
})

export default LUTheme
