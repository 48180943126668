import * as React from "react"
import cx from "classnames"
import { isEmpty } from "@leeruniek/functies"

import css from "./css/checkbox.module.css"

export class LUCheckbox extends React.Component {
  static defaultProps = {
    className: "",
    name: "",
    colorTheme: "",
    color: "",
    isDisabled: false,
    isChecked: false,
    onChange: null,
    classes: {},
  }

  render() {
    const {
      isDisabled,
      isChecked,
      name,
      label,
      className,
      color,
      colorTheme,
      size,
      classes,
      onChange,
    } = this.props

    return (
      <label
        className={cx(css.field, {
          [css.disabled]: isDisabled,
          [className || ""]: !isEmpty(className),
          [css[colorTheme]]: !isEmpty(colorTheme),
          [css.small]: size === "small",
        })}>
        <input
          type="checkbox"
          name={name}
          disabled={isDisabled}
          checked={isChecked}
          className={css.input}
          onChange={onChange}
          data-testid={"lucheckbox"}
        />
        <div
          className={cx(css.check, {
            [classes.checkbox]: classes.checkbox,
            [css.checked]: isChecked,
            [classes.checkboxChecked]: classes.checkboxChecked && isChecked,
          })}
          style={
            isChecked && !isEmpty(color)
              ? {
                  backgroundColor: color,
                  borderColor: color,
                }
              : null
          }
        />
        {label ? (
          <span
            className={cx(css.text, {
              [classes.label]: classes.label,
            })}>
            {label}
          </span>
        ) : null}
      </label>
    )
  }
}
