/**
 * Custom error for API requests
 *
 * @class RequestError
 */
export default class RequestError extends Error {
  _isAccessError: boolean
  _isAuthError: boolean
  _isNotFoundError: boolean
  _isAppError: boolean
  code: number
  body: string

  constructor(errorData: { status: number; statusText: string }, body: string) {
    super(`${errorData.status}:${errorData.statusText}`)

    this.name = "RequestError"
    this.code = errorData.status
    this.body = body

    this._isAccessError = errorData.status === 403
    this._isAuthError = errorData.status === 401
    this._isNotFoundError = errorData.status === 404
    this._isAppError = !this._isAccessError && !this._isNotFoundError
  }
}
