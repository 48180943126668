import React from "react"
import PropTypes from "prop-types"
import css from "components/error/index.module.scss"

import { history } from "store"

import { t } from "components/error/index.i18n"
import { UserLayoutFooter } from "entities/user.layout/user__footer"
import MLUButton from "components/ui/mlubutton/mlubutton"

export default class UnknownErrorView extends React.Component {
  static propTypes = {
    cancelErrorOccurred: PropTypes.func.isRequired,
  }

  state = {
    showFeedbackForm: false,
  }

  handleFeedbackClose = () => {
    this.setState({
      showFeedbackForm: false,
    })
  }

  handleChangeLocation = () => {
    history.push("/app")
    window.location.reload()
  }

  render() {
    const { showFeedbackForm } = this.state
    return (
      <div className={css.text_wrapper} data-testid={"unknown_error_view"}>
        <div className={css.text_body}>
          <h1 className={css.error_title}>
            {t("unexpected__error__message__oeps")}
          </h1>
          <p>{t("unexpected__error__message__part__1")}</p>
          <p>{t("unexpected__error__message__part__2")}</p>
          <br />
          <p>{t("unexpected__error__message__part__3")} </p>
        </div>
        <div className={css.buttons_wrapper}>
          <MLUButton
            onClick={this.handleChangeLocation}
            className={css.go_to_homepage_button}
            label={t("error__go__homepage")}
          />
        </div>
        <div className={css.error_logo} />
        <UserLayoutFooter
          key="user-layout__footer"
          showFeedbackForm={showFeedbackForm}
          handleFeedbackCloseCallback={this.handleFeedbackClose}
        />
      </div>
    )
  }
}
