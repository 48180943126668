import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { GET, POST, PATCH, DELETE } from "lib/core/api"
import { map } from "ramda"
import { GroupPlanNote } from "entities/group-plans/group-plans.interfaces"
import { serializeError } from "lib/core/redux-tools.utils"
import {
  NoteCategory,
  NoteCategoryAutocomplete,
} from "entities/note-categories/note-categories.interfaces"

export const getNoteCategories = createAsyncThunk<NoteCategory[], number>(
  "adminTemplatesSelected/getNoteCategories",
  async (templateId: number) => {
    const result = await GET("/note_categories", {
      query: {
        group_plan_id: templateId,
        "include[]": "id,name,parent_note_category_id,position",
      },
    }).then(
      map((category: NoteCategory) => ({
        ...category,
        isCollapsed: false,
      })),
    )

    return result as NoteCategory[]
  },
  {
    serializeError,
  },
)

export const createNoteCategory = createAsyncThunk<
  NoteCategory,
  {
    planId: number
    parentNoteCategoryId: number
    name: string
    position: number
  }
>(
  "adminTemplatesSelected/createNoteCategory",
  async (data) => {
    const result = await POST("/note_categories", {
      layer: "GROUP",
      group_plan_id: data.planId,
      parent_note_category_id: data.parentNoteCategoryId,
      name: data.name,
      position: data.position,
    }).then((category: NoteCategory) => ({
      ...category,
      isCollapsed: false,
    }))

    return result as NoteCategory
  },
  {
    serializeError,
  },
)

export const updateNoteCategory = createAsyncThunk<
  NoteCategory,
  { id: number; data: Partial<NoteCategory> }
>(
  "adminTemplatesSelected/updateNoteCategory",
  async (args) => {
    const { isCollapsed, ...data } = args.data

    const result = await PATCH(`/note_categories/${args.id}`, {
      body: data,
    }).then((category: NoteCategory) => ({
      ...category,
      isCollapsed: isCollapsed,
    }))

    return result as NoteCategory
  },
  {
    serializeError,
  },
)

export const updateNoteCategoryInState = createAction(
  "adminTemplatesSelected/updateNoteCategoryInState",
  (args: { id: number; data: Partial<NoteCategory> }) => ({ payload: args }),
)

export const deleteNoteCategory = createAsyncThunk<{ id: number }, number>(
  "adminTemplatesSelected/deleteNoteCategory",
  async (id: number) => {
    const result = await DELETE(`/note_categories/${id}`).then(() => ({ id }))

    return result as { id: number }
  },
  {
    serializeError,
  },
)

export const getNotes = createAsyncThunk<GroupPlanNote[], number>(
  "adminTemplatesSelected/getNotes",
  async (templateId: number) => {
    const result = await GET("/group_plan_note/", {
      query: {
        category__group_plan_id: templateId,
        category__is_archived: false,
        date_archived__isnull: true,
        "exclude[]": "pupil_id",
      },
    })

    return result as GroupPlanNote[]
  },
  {
    serializeError,
  },
)

export const createNote = createAsyncThunk<
  GroupPlanNote,
  Partial<GroupPlanNote>
>(
  "adminTemplatesSelected/createNote",
  async (data) => {
    const result = await POST("/group_plan_note/", {
      body: data,
    })

    return result
  },
  {
    serializeError,
  },
)

export const updateNote = createAsyncThunk<
  GroupPlanNote,
  { id: number; data: Partial<GroupPlanNote> }
>(
  "adminTemplatesSelected/updateNote",
  async (args) => {
    const result = await PATCH(`/group_plan_note/${args.id}`, {
      body: args.data,
    })

    return result as GroupPlanNote
  },
  {
    serializeError,
  },
)

export const updateNoteInState = createAction(
  "adminTemplatesSelected/updateNoteInState",
  (args: { id: number; data: Partial<GroupPlanNote> }) => ({ payload: args }),
)

export const deleteNote = createAsyncThunk<{ id: number }, number>(
  "adminTemplatesSelected/deleteNote",
  async (id: number) => {
    const result = await DELETE(`/group_plan_note/${id}`).then(() => ({ id }))

    return result as { id: number }
  },
  {
    serializeError,
  },
)

export const getCategoriesAutocomplete = createAsyncThunk<
  NoteCategoryAutocomplete[],
  number
>(
  "adminTemplatesSelected/getCategoriesAutocomplete",
  async (planId: number) => {
    const result = await GET("/note_categories", {
      query: {
        "~group_plan_id": planId,
        user: true,
        parent_note_category_id__isnull: true,
        distinct_by: "name",
        "include[]": "id,name",
        layer__in: "GROUP,INSTRUCTION",
      },
    })

    return result as NoteCategoryAutocomplete[]
  },
  {
    serializeError,
  },
)

export const getSubcategoriesAutocomplete = createAsyncThunk<
  NoteCategoryAutocomplete[],
  number
>(
  "adminTemplatesSelected/getSubcategoriesAutocomplete",
  async (planId: number) => {
    const result = await GET("/note_categories", {
      query: {
        "~group_plan_id": planId,
        user: true,
        parent_note_category_id__isnull: false,
        distinct_by: "name",
        "include[]": "id,name",
        layer__in: "GROUP,INSTRUCTION",
      },
    })

    return result as NoteCategoryAutocomplete[]
  },
  {
    serializeError,
  },
)
