import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["login"]: {
    nl: "Inloggen",
    en: "Login",
  },
  ["activation-code"]: {
    nl: "Activatie code",
    en: "Activation code",
  },
  ["verify"]: {
    nl: "Verifiëren",
    en: "Verify",
  },
  ["login__error__invalid-multi-factor-auth-code"]: {
    nl: "De ingevoerde code is ongeldig. Probeer het nog eens.",
    en: "The entered code is invalid. Try again.",
  },
  ["login__error"]: {
    nl: "Kan niet inloggen met opgegeven gegevens.",
    en: "Cannot login with specified data.",
  },
  ["login__error__forgot-password-question"]: {
    nl: "Ben je je wachtwoord vergeten?",
    en: "Have you forgotten your password?",
  },
  ["login__error__forgot-password-advice"]: {
    nl: "Klik dan op de link 'wachtwoord vergeten'.",
    en: "Click on the 'forgot password' link.",
  },
  ["login__error__no-username-question"]: {
    nl: "Heb je nog geen gebruikersnaam?",
    en: "Don't have a username yet?",
  },
  ["login__error__no-username-advice"]: {
    nl: "Vraag aan je IB'er om er een aan te maken.",
    en: "Ask your administrator to create one for you.",
  },
  ["login__username-placeholder"]: {
    nl: "Gebruikersnaam",
    en: "Username",
  },
  ["login__password-placeholder"]: {
    nl: "Wachtwoord",
    en: "Password",
  },
  ["login__forgot-password"]: {
    nl: "Wachtwoord vergeten?",
    en: "Forgot password?",
  },
  ["login__remember-me"]: {
    nl: "Onthoud mijn inloggegevens",
    en: "Remember me",
  },
  ["login__error__school-not-paying"]: {
    nl: "Op dit moment heeft u geen rechten om in te loggen in Leeruniek. Dit kan zijn omdat uw school geen actief account heeft bij ons. Neem contact op met onze supportafdeling via support@leeruniek.nl.",
    en: "At the moment you do not have any permission to log into Leeruniek. This could mean that your school has no active account. Please contact our support team at support@leeruniek.nl",
  },
  ["login__mfa-guide"]: {
    nl: `
      <ol>
        <li>Open de authenticatie-app op je telefoon</li>
        <li>Vul de verificatiecode uit de authenticatie-app in</li>
      </ol>`,
    en: `
      <ol>
        <li>Open the authenticator app on your phone</li>
        <li>Fill in the verification code of Leeruniek generated by the authenticator app</li>
      </ol>`,
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
