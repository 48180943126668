import { GET, POST, DELETE, PATCH } from "lib/core/api"

/**
 * Create a new Note
 *
 * @param  {Object}         data  The data
 *
 * @return {Promise<Note>}  New Note object
 */
export const create = ({ pupilId, category, content, position }) =>
  POST("/pupil_note", {
    body: {
      pupilId,
      category,
      content,
      position,
    },
  })

/**
 * Find all notes by pupil id
 *
 * @param  {integer}          id    The Pupil identifier
 *
 * @return {Promise}  Array of pupil notes
 */
export const findAllByPupilId = (id) =>
  GET("/pupil_note", {
    query: {
      pupil_id: id,
      category__layer: "PUPIL",
      "~category__name__in": "method_score,cito_score,social_emotional",
    },
  })

/**
 * Update one Note by id
 *
 * @param  {integer}        id    The Note identifier
 * @param  {Object}         data  The data
 *
 * @return {Promise<Note>}  Updated Note object
 */
export const update = (
  id,
  { pupilId, category, content, position, archived },
) =>
  PATCH(`/pupil_note/${id}`, {
    body: {
      pupilId,
      category,
      content,
      archived,
      position,
    },
  })

/**
 * Delete one Note by id
 *
 * @param  {integer}           id  The Note identifier
 *
 * @return {Promise<boolean>}
 */
export const remove = (id) => DELETE(`/pupil_note/${id}/`)
