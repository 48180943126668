import { createReducer, SerializedError } from "@reduxjs/toolkit"
import {
  GroupPlan,
  GroupPlanNote,
  GroupPlanTemplate,
} from "entities/group-plans/group-plans.interfaces"
import { NoteCategory } from "entities/note-categories/note-categories.interfaces"
import {
  getGroupPlans,
  createGroupPlan,
  updateGroupPlan,
  deleteGroupPlan,
  getClonableGroupPlans,
  getGroupPlanTemplates,
  getGroupPlanNoteCategoriesPreview,
  clearGroupPlanNoteCategoriesPreview,
  getGroupPlanNotesPreview,
  clearGroupPlanNotesPreview,
} from "entities/groups__worksheet.page/plans.section/plans.actions"

interface State {
  plans: GroupPlan[]
  notesPreview: GroupPlanNote[]
  noteCategoriesPreview: NoteCategory[]
  planTemplates: GroupPlanTemplate[]
  clonablePlans: GroupPlan[]
  isPlansLoading: boolean
  isPlansCreating: boolean
  isPlansUpdating: boolean
  isPlansDeleting: boolean
  isNoteCategoriesPreviewLoading: boolean
  isNotesPreviewLoading: boolean
  isClonablePlansLoading: boolean
  error: SerializedError | null
}

const initialState: State = {
  plans: [],
  notesPreview: [],
  noteCategoriesPreview: [],
  planTemplates: [],
  clonablePlans: [],
  isPlansLoading: false,
  isPlansCreating: false,
  isPlansUpdating: false,
  isPlansDeleting: false,
  isNoteCategoriesPreviewLoading: false,
  isNotesPreviewLoading: false,
  isClonablePlansLoading: false,
  error: null,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(getGroupPlans.pending, (state) => {
    state.isPlansLoading = true
  })
  builder.addCase(getGroupPlans.fulfilled, (state, action) => {
    state.isPlansLoading = false
    state.plans = action.payload
  })
  builder.addCase(getGroupPlans.rejected, (state, action) => {
    state.isPlansLoading = false
    state.error = action.error
  })
  builder.addCase(createGroupPlan.pending, (state) => {
    state.isPlansCreating = true
  })
  builder.addCase(createGroupPlan.fulfilled, (state, action) => {
    state.isPlansCreating = false
    state.plans.push(action.payload)
  })
  builder.addCase(createGroupPlan.rejected, (state, action) => {
    state.isPlansCreating = false
    state.error = action.error
  })
  builder.addCase(updateGroupPlan.pending, (state) => {
    state.isPlansUpdating = true
  })
  builder.addCase(updateGroupPlan.fulfilled, (state, action) => {
    state.isPlansUpdating = false
    const updatedIndex = state.plans.findIndex(
      (plan) => plan.id === action.meta.arg.id,
    )

    if (updatedIndex !== -1) {
      state.plans[updatedIndex] = action.payload
    }
  })
  builder.addCase(updateGroupPlan.rejected, (state, action) => {
    state.isPlansUpdating = false
    state.error = action.error
  })
  builder.addCase(deleteGroupPlan.pending, (state) => {
    state.isPlansDeleting = true
  })
  builder.addCase(deleteGroupPlan.fulfilled, (state, action) => {
    state.isPlansDeleting = false
    state.plans = state.plans.filter((plan) => plan.id !== action.meta.arg)
  })
  builder.addCase(deleteGroupPlan.rejected, (state, action) => {
    state.isPlansDeleting = false
    state.error = action.error
  })
  builder.addCase(getClonableGroupPlans.pending, (state) => {
    state.isClonablePlansLoading = true
  })
  builder.addCase(getClonableGroupPlans.fulfilled, (state, action) => {
    state.isClonablePlansLoading = false
    state.clonablePlans = action.payload
  })
  builder.addCase(getClonableGroupPlans.rejected, (state, action) => {
    state.isClonablePlansLoading = false
    state.error = action.error
  })
  builder.addCase(getGroupPlanTemplates.pending, (state) => state)
  builder.addCase(getGroupPlanTemplates.fulfilled, (state, action) => {
    state.planTemplates = action.payload
  })
  builder.addCase(getGroupPlanTemplates.rejected, (state, action) => {
    state.error = action.error
  })
  builder.addCase(getGroupPlanNoteCategoriesPreview.pending, (state) => {
    state.isNoteCategoriesPreviewLoading = true
  })
  builder.addCase(
    getGroupPlanNoteCategoriesPreview.fulfilled,
    (state, action) => {
      state.isNoteCategoriesPreviewLoading = false
      state.noteCategoriesPreview = action.payload
    },
  )
  builder.addCase(
    getGroupPlanNoteCategoriesPreview.rejected,
    (state, action) => {
      state.isNoteCategoriesPreviewLoading = false
      state.error = action.error
    },
  )
  builder.addCase(clearGroupPlanNoteCategoriesPreview, (state) => {
    state.noteCategoriesPreview = []
  })
  builder.addCase(getGroupPlanNotesPreview.pending, (state) => {
    state.isNotesPreviewLoading = true
  })
  builder.addCase(getGroupPlanNotesPreview.fulfilled, (state, action) => {
    state.isNotesPreviewLoading = false
    state.notesPreview = action.payload
  })
  builder.addCase(getGroupPlanNotesPreview.rejected, (state, action) => {
    state.isNotesPreviewLoading = false
    state.error = action.error
  })
  builder.addCase(clearGroupPlanNotesPreview, (state) => {
    state.notesPreview = []
  })
})
