import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

@withRouter
export default class ScrollToTop extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,

    // router
    location: PropTypes.object.isRequired,
  }

  /*
   * Invoked immediately after updating occurs. This method is not called for
   * the initial render.
   */
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    return this.props.children
  }
}
