import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isNil } from "ramda"

import DoorbellFormContainer from "entities/doorbell/doorbell-form.view"
import { getParams } from "lib/core/router.utils"
import { trackSupportDialogOpen } from "entities/doorbell/doorbell-form.tracking"
import MLUButton from "components/ui/mlubutton/mlubutton"
import { t } from "lib/core/i18n/root.i18"
import css from "entities/user.layout/user.module.scss"

import { SUPPORT_TICKET_OPTIONS } from "lib/leeruniek/constants"

@withRouter
@connect((store) => {
  const urlParams = getParams(store.router.location.pathname, "school")
  const schoolId = parseInt(urlParams.schoolId, 10)
  const sessionSchool = store.session.schools?.find(
    (school) => school.id === schoolId,
  )

  return {
    sessionSchoolName: !isNil(sessionSchool) ? sessionSchool.name : null,
    sessionSchoolBRIN: !isNil(sessionSchool) ? sessionSchool.brinCode : null,
    currentUser: store.session.user,
  }
})
class UserLayoutFooter extends React.Component {
  static propTypes = {
    sessionSchoolName: PropTypes.string,
    sessionSchoolBRIN: PropTypes.string,
    support: PropTypes.node,
    handleFeedbackCloseCallback: PropTypes.func,
    showFeedbackForm: PropTypes.bool,
  }

  static defaultProps = {
    currentUser: null,
    support: null,
    showFeedbackForm: false,
  }

  state = {
    showFeedbackForm: this.props.showFeedbackForm,
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    const {
      support,
      currentUser,
      sessionSchoolName,
      showFeedbackForm: showFeedbackFormFromProps,
    } = this.props
    const { showFeedbackForm } = this.state

    return currentUser && sessionSchoolName ? (
      <div className={cx(css.footer, "animated", "fadeIn", "dont-print")}>
        <DoorbellFormContainer
          isVisible={showFeedbackForm || showFeedbackFormFromProps}
          ticketOptions={SUPPORT_TICKET_OPTIONS}
          onClose={this.handleFeedbackClose}
          onSubmit={this.handleFeedbackClose}
        />

        <MLUButton
          className={cx("pull-right", css.footer__feedback)}
          label={t("support")}
          color="yellow"
          icon={<i className="fa fa-paper-plane" />}
          onClick={this.handleFeedbackShow}
        />

        {support ? (
          <div className={cx("pull-right", css.footer__support)}>{support}</div>
        ) : null}
      </div>
    ) : null
  }

  /**
   * Close feedback form
   *
   * @return {undefined}
   */
  handleFeedbackClose = () => {
    this.setState({
      showFeedbackForm: false,
    })
    if (this.props.handleFeedbackCloseCallback) {
      this.props.handleFeedbackCloseCallback()
    }
  }

  /**
   * Open feedback form
   *
   * @return {undefined}
   */
  handleFeedbackShow = () => {
    this.setState({
      showFeedbackForm: true,
    })
    trackSupportDialogOpen(this.props.match)
  }
}

export { UserLayoutFooter }
