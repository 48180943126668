import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["doorbell_dialog_title"]: {
    nl: "Hoe kunnen wij je helpen?",
    en: "How we can help you?",
  },
  ["doorbell_documentation_callout"]: {
    nl: "Veel gebruikers vinden hun antwoord in onze handreikingen",
    en: "Many users find their answer in our guides",
  },
  ["doorbell_feedback_callout"]: {
    nl: "Typ anders je vraag in het vak hiernaast. Er wordt automatisch een screenshot bijgevoegd zodat we je vraag beter kunnen beantwoorden. Ook als je feedback hebt voor het product, horen we dat natuurlijk graag.",
    en: "If not, ask via the form on the right. A screenshot of the current page will be automatically attached. If you have feedback for the product, we'd love to hear it!",
  },
  ["doorbell_subject_placeholder"]: {
    nl: "Onderwerp van feedback",
    en: "Subject of your question",
  },
  ["doorbell_ticket_message_placeholder"]: {
    nl: "Schrijf hier jouw vraag of suggestie",
    en: "Write your question or suggestion here",
  },
  ["doorbell_message_label"]: {
    nl: "Laat hier jouw vragen en/of suggesties achter",
    en: "Leave your questions/comments here",
  },
  ["doorbell_notify_success"]: {
    nl: "Hartelijk dank voor de feedback, je hoort zo snel mogelijk van ons! ",
    en: "Thanks for your feedback, we will get back to you as soon as possible! ",
  },
  ["doorbell_include_screenshot"]: {
    nl: "Voeg een screenshot toe",
    en: "Add a screenshot",
  },
  ["doorbell_users_tutorial_text"]: {
    nl: "Gebruikers",
    en: "Users",
  },
  ["doorbell_half_yearly_analysis_link_text"]: {
    nl: "Halfjaarlijkse analyse",
    en: "Half-yearly analysis",
  },
  ["doorbell_methods_tutorial_link_text"]: {
    nl: "Methodetoetsen",
    en: "Method tests",
  },
  ["tutorial_menu__title"]: {
    nl: "Gaat je vraag over:",
    en: "Is your question about:",
  },
  ["tutorial_menu__go_to_form"]: {
    nl: "Ik wil een bericht sturen",
    en: "I want to send a message",
  },

  ["doorbell_ticket_option_tests"]: {
    nl: "Toetsen",
    en: "Tests",
  },
  ["doorbell_ticket_option_users"]: {
    nl: "Gebruikers",
    en: "Users",
  },
  ["doorbell_ticket_option_key_definitions"]: {
    nl: "Toetsdefinities",
    en: "Key Definitions",
  },
  ["doorbell_ticket_option_pupil"]: {
    nl: "Leerling",
    en: "Pupil",
  },
  ["doorbell_ticket_option_other"]: {
    nl: "Anders",
    en: "Other",
  },

  ["doorbell_helper_text_tests"]: {
    nl: `
    Gaat je vraag over (een) specifieke toets(en)?
    Voeg een screenshot toe van de ParnasSys-weergave van deze toets en schrijf op of je toets in ParnasSys of in Resultatenmonitor hebt ingevoerd.
    Zo kan ons supportteam je beter helpen.
    `,
    en: `
    Is your question about (a) specific test(s)?
    Attach a screenshot of the ParnasSys representation of this test and write down whether you entered the test in ParnasSys or in Results Monitor.
    This way our support team can help you better.
    `,
  },
  ["doorbell_helper_text_users"]: {
    nl: `
    Gaat je vraag over een andere Leeruniek-gebruiker?
    Voeg dan het e-mailadres van deze gebruiker toe, dan kan ons supportteam je zo snel mogelijk helpen.
    `,
    en: `
    Is your question about another Leeruniek user?
    Then add the email address of this user and our support team can help you as soon as possible.
    `,
  },
  ["doorbell_helper_text_key_definitions"]: {
    nl: `
    Gaat je vraag over toetsdefinities?
    Voeg je bestand(en) als bijlage(n) toe aan je bericht, dan kan ons supportteam je zo snel mogelijk helpen.
    `,
    en: `
    Is your question about test definitions?
    Attach your file(s) to your message and our support team can help you as soon as possible.
    `,
  },
  ["doorbell_helper_text_pupil"]: {
    nl: `
    Gaat je vraag over een leerling?
    Voeg een screenshot toe van de ParnasSys-weergave van deze leerling, dan kan ons supportteam je zo snel mogelijk helpen.
    `,
    en: `
    Is your question about a student?
    Please include a screenshot of this student's ParnasSys view and our support team will be able to assist you as soon as possible.
    `,
  },

  ["doorbell_add_attachment"]: {
    nl: "Voeg bijlage toe",
    en: "Add attachment",
  },

  ["doorbell_file_size_threshold"]: {
    nl: "Max. 5MB per bestand",
    en: "Max. 5MB per file",
  },

  ["doorbell_file_too_large_warning"]: {
    nl: "Het bestand dat je probeert te uploaden is te groot. Bestanden groter dan 5MB kun je met ons delen via support@leeruniek.nl.",
    en: "The file you are trying to upload is too large. Files larger than 5MB can be shared with us via support@leeruniek.nl.",
  },

  ["doorbell_delete_attachment"]: {
    nl: "verwijder",
    en: "delete",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
