import { GET, POST, PATCH, DELETE } from "lib/core/api"
import { toUnderscore } from "lib/core/utils"
import { SortingArgs } from "entities/admin__templates.page/admin__templates.interfaces"
import {
  GroupPlan,
  GroupPlanNote,
  GroupPlanTemplate,
} from "entities/group-plans/group-plans.interfaces"
import { NoteCategory } from "entities/note-categories/note-categories.interfaces"
import { createAsyncThunk, createAction } from "@reduxjs/toolkit"
import { serializeError } from "lib/core/redux-tools.utils"

export const getAdminTemplates = createAsyncThunk<
  GroupPlanTemplate[],
  { schoolId: number; sort: SortingArgs }
>(
  "adminTemplates/getAdminTemplates",
  async (args) => {
    const result = await GET("/group_plan_templates/", {
      query: {
        school_id: args.schoolId,
        "include[]":
          "id,name,is_published,is_system,user_name,date_changed,allowed_subjects,allowed_grades",
        ordering:
          args.sort && args.sort.sortField
            ? `${args.sort.sortDirection === "ASC" ? "+" : "-"}${toUnderscore(
                args.sort.sortField,
              )}`
            : undefined,
      },
    })

    return result as GroupPlanTemplate[]
  },
  { serializeError },
)

export const createAdminTemplate = createAsyncThunk<
  GroupPlanTemplate,
  Partial<GroupPlanTemplate>
>(
  "adminTemplates/createAdminTemplate",
  async (data: Partial<GroupPlanTemplate>) => {
    const result = await POST("/group_plan_templates/", {
      ...data,
    })

    return result as GroupPlanTemplate
  },
  {
    serializeError,
  },
)

export const updateAdminTemplate = createAsyncThunk<
  GroupPlanTemplate,
  { id: number; data: Partial<GroupPlanTemplate> }
>(
  "adminTemplates/updateAdminTemplate",
  async (args) => {
    const result = await PATCH(`/group_plan_templates/${args.id}/`, {
      body: args.data,
    })

    return result as GroupPlanTemplate
  },
  { serializeError },
)

export const deleteAdminTemplate = createAsyncThunk<{ id: number }, number>(
  "adminTemplates/deleteAdminTemplate",
  async (id: number) => {
    const result = await DELETE(`/group_plan_templates/${id}`).then(() => ({
      id,
    }))

    return result as { id: number }
  },
  {
    serializeError,
  },
)

export const getRecentPlans = createAsyncThunk<GroupPlan[], number>(
  "adminTemplates/getRecentPlans",
  async (schoolId: number) => {
    const result = await GET("/recent_plans", {
      query: {
        "include[]": [
          "id",
          "name",
          "userName",
          "yearclassId",
          "yearclassName",
          "yearclassCalendarYear",
        ],
        school_id: schoolId,
      },
    })

    return result as GroupPlan[]
  },
  {
    serializeError,
  },
)

export const getNoteCategoriesPreview = createAsyncThunk<
  NoteCategory[],
  number
>(
  "adminTemplates/getNoteCategoriesPreview",
  async (parentId) => {
    const result = await GET("/note_categories", {
      query: {
        group_plan_id: parentId,
        "include[]": "id,name,parent_note_category_id,position",
      },
    })

    return result as NoteCategory[]
  },
  {
    serializeError,
  },
)

export const clearNoteCategoriesPreview = createAction(
  "adminTemplates/clearNoteCategoriesPreview",
)

export const getGroupPlanNotesPreview = createAsyncThunk<
  GroupPlanNote[],
  number
>(
  "adminTemplates/getGroupPlanNotesPreview",
  async (parentId) => {
    const result = await GET("/group_plan_note", {
      query: {
        category__group_plan_id: parentId,
        category__is_archived: false,
        date_archived__isnull: true,
        "exclude[]": "pupil_id",
      },
    })

    return result as GroupPlanNote[]
  },
  {
    serializeError,
  },
)

export const clearGroupPlanNotesPreview = createAction(
  "adminTemplates/clearGroupPlanNotesPreview",
)
