import React from "react"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

import * as RouterUtils from "lib/core/router.utils"
import AccountLoginPage from "entities/account__login.page/account__login.page"
import AccountInvitePage from "entities/account__invite.page/account__invite.page"

@connect((store) => ({
  isAuthorized: store.session.isUserAuthorized,
}))
export default class GuestLayout extends React.Component {
  static propTypes = {
    isAuthorized: PropTypes.bool,
  }

  // Defaults for non-required props
  static defaultProps = {
    isAuthorized: false,
  }

  /**
   * Main render
   *
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    return (
      <Switch>
        <Route
          path={RouterUtils.get("account__login")}
          component={AccountLoginPage}
        />
        <Route
          path={RouterUtils.get("account__invite")}
          component={AccountInvitePage}
        />
        <Redirect to={RouterUtils.get("account__login")} />
      </Switch>
    )
  }
}
