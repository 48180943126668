import React from "react"

import { sanitize } from "dompurify"
import * as StorageAPI from "lib/core/storage.api"
import {
  getCurrentSchoolYear,
  isWithinSchoolYearStartPeriod,
} from "lib/leeruniek/time-helpers"
import MLUButton, { MLUButtonSize } from "components/ui/mlubutton/mlubutton"
import MLUDialog, {
  ActionsAlignment,
  DialogSize,
} from "components/ui/mludialog/mludialog"
import { LUCheckbox } from "webclient-ui/components"
import css from "./new-school-year-notification.module.scss"
import { t } from "./new-school-year-notification.i18n"
import { connect } from "react-redux"

export const STORAGE_KEY = "hideNewSchoolYearNotificationForSchoolYear"

interface NewSchoolYearNotificationProps {
  isSchoolSuperuser: boolean
}

const NewSchoolYearNotification = ({
  isSchoolSuperuser,
}: NewSchoolYearNotificationProps) => {
  const valueFromStorage = StorageAPI.find(STORAGE_KEY)
  const [hideNotification, setHideNotification] = React.useState(
    Boolean(
      valueFromStorage && Number(valueFromStorage) === getCurrentSchoolYear(),
    ),
  )
  const [isChecked, setIsChecked] = React.useState(false)

  const handleClose = () => {
    if (isChecked) {
      StorageAPI.upsert(
        {
          key: STORAGE_KEY,
          value: getCurrentSchoolYear(),
        },
        { local: true },
      )
    }
    setHideNotification(!hideNotification)
  }

  return isSchoolSuperuser &&
    isWithinSchoolYearStartPeriod() &&
    !hideNotification ? (
    <MLUDialog
      open={!hideNotification}
      className={css["notification-container"]}
      color="yellow"
      size={DialogSize.SMALL}
      title={t("new-schoolyear")}
      actions={[
        <MLUButton
          key="btn-close"
          label={t("close")}
          size={MLUButtonSize.SMALL}
          onClick={handleClose}
        />,
      ]}
      actionsAlignment={ActionsAlignment.CENTER}>
      <p
        dangerouslySetInnerHTML={{
          __html: sanitize(t("new-school-year-notification")),
        }}
      />
      <LUCheckbox
        label={t("hide-new-school-notification")}
        size="small"
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
    </MLUDialog>
  ) : null
}

export default connect(
  (state: { school: { school: { isSchoolSuperuser: boolean } } }) => ({
    isSchoolSuperuser: state.school.school.isSchoolSuperuser,
  }),
)(NewSchoolYearNotification)
