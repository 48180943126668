import React from "react"
import { t } from "entities/doorbell/doorbell-form.i18n"
import css from "entities/doorbell/doorbell-form.module.scss"

import {
  trackClickTutorialLink,
  trackGoToForm,
} from "entities/doorbell/doorbell-form.tracking"
import MLUButton, {
  MLUButtonSize,
  MLUButtonType,
} from "components/ui/mlubutton/mlubutton"

export const TutorialMenuSection = ({ links, onSupportFormShowClick }) => {
  const handleShowSupportFormClick = (event) => {
    event.preventDefault()
    onSupportFormShowClick()
    trackGoToForm()
  }

  return (
    <div className={css["doorbell_form__tutorial_menu__container"]}>
      <h6 className={css["title"]}>{t("tutorial_menu__title")}</h6>
      <div
        className={css["doorbell_form__tutorial_menu"]}
        data-testid={"doorbell_form__tutorial_menu"}>
        {links.map((link) => {
          return (
            <a
              href={t(link.urlKey)}
              target="_blank"
              rel="noopener noreferrer"
              key={`link_to_${link.urlKey}`}
              className={css["doorbell_form__tutorial_menu__link_tile"]}
              data-testid={"doorbell_form__tutorial_menu__link_tile"}
              onClick={() =>
                trackClickTutorialLink("link view", t(link.titleKey))
              }>
              {t(link.titleKey)}
            </a>
          )
        })}
      </div>
      <div className={css["doorbell_form__tutorial_menu__goto_form_ctrl"]}>
        <MLUButton
          label={t("tutorial_menu__go_to_form")}
          type={MLUButtonType.TEXT}
          size={MLUButtonSize.SMALL}
          color="gray"
          onClick={handleShowSupportFormClick}
        />
      </div>
    </div>
  )
}
